<template>
  <GenericDetailsComponent :title="project.shortname"
                           :show-title="true"
                           :show-save-button="showSaveButton"
                           :save-button-disabled="!valid"
                           @save="updateProject">
    <template #default>
      <v-form ref="form"
              v-model="valid"
              lazy-validation>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="project.client_shortname"
                          :label="$tc('Client')"
                          readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="shortname"
                          :label="$t('Project name')"
                          :readonly="!editable"
                          :rules="shortnameRules"
                          @input="validateForm"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="description"
                          :label="$t('Project description')"
                          :rules="descriptionRules"
                          :readonly="!editable"
                          @input="compareValues"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="project.tz"
                          :label="$t('Timezone')"
                          readonly></v-text-field>
          </v-col >
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="country"
                          :label="$t('Country')"
                          readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field v-model="county"
                          :label="$t('County')"
                          readonly></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </GenericDetailsComponent>
  <!--  <base-card-component :title="project.shortname" :show-title="true">-->
  <!--    <template #action>-->
  <!--      <v-chip v-if="project.is_active" color="success">{{ $t('active') }}</v-chip>-->
  <!--      <v-chip v-if="!project.is_active" color="error">{{ $t('inactive') }}</v-chip>-->
  <!--      <div v-if="!editable">-->
  <!--        <base-button-component @click="editable=true">-->
  <!--          <v-icon>mdi-pencil</v-icon>-->
  <!--        </base-button-component>-->
  <!--      </div>-->
  <!--      <div v-else>-->
  <!--        <base-button-component @click="editable=false">-->
  <!--          <v-icon>mdi-arrow-u-left-top</v-icon>-->
  <!--        </base-button-component>-->
  <!--        <base-button-component @click="updateProject">-->
  <!--          <v-icon>mdi-content-save-check</v-icon>-->
  <!--        </base-button-component>-->
  <!--      </div>-->
  <!--    </template>-->

  <!--    <template #default v-if="editable">-->
  <!--      <v-form ref="projectform" v-model="valid">-->
  <!--        <v-row>-->
  <!--          <v-col sm="8">-->
  <!--            <v-autocomplete-->
  <!--                v-model="client"-->
  <!--                :items="clientlist"-->
  <!--                item-text="shortname"-->
  <!--                item-value="id"-->
  <!--                :search-input.sync="search"-->
  <!--                :label="$tc('Client')"-->
  <!--                placeholder="Start typing to search"-->
  <!--                :rules="[v => !!v || 'Item is required']"-->
  <!--                return-object-->
  <!--                clearable-->
  <!--                auto-select-first-->
  <!--                required-->
  <!--            ></v-autocomplete>-->
  <!--          </v-col>-->
  <!--        </v-row>-->
</template>

<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import api from "@/ApiFactory";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent.vue";
import GenericDetailsComponent from "@/components/templates/GenericDetailsComponent.vue";

export default {
  components: {GenericDetailsComponent, BaseButtonComponent, BaseCardComponent},
  props: ['project'],
  data() {
    return {
      valid: false,
      client: null,
      shortname: '',
      shortnameRules: [
        v => !!v || 'is required',
        v => v.length <= 24 || 'max length 24 characters'
      ],
      description: '',
      descriptionRules: [
        v => !!v || 'is required'
      ],
      country: '',
      county: '',
      // clientlist: null,
      // search: null,
      editable: false,
      showSaveButton: false
    }
  },
  // mounted() {
  //   api.clients.list()
  //       .then(response => {
  //         this.clientlist = response.data.results
  //       })
  // },
  methods: {
    updateProject() {
      console.log(typeof this.client)
      api.projects.update(this.project.id,
          {
            shortname: this.shortname,
            description: this.description,
            client: this.client,
            tz: this.project.tz
          }).then(project => {
            this.$emit('update:project', project.data)
            this.$root.vtoast.show({message: this.$tc('Successfully updated project')})
            this.editable = false
          }
      )
    },
    compareValues() {
      this.showSaveButton = false
      if (this.shortname !== this.project.shortname) this.showSaveButton = true
      if (this.description !== this.project.description) this.showSaveButton = true
    },
    validateForm() {
      this.$refs.form.validate()
      this.compareValues()
    }
  },
  watch: {
    project(value) {
      this.client = this.project.client
      this.shortname = this.project.shortname
      this.description = this.project.description
      this.country = (this.$i18n.locale==='de') ? this.project.country.country_name_de : this.project.country.country_name_en
      if (this.project.county) {
        this.county = (this.$i18n.locale==='de') ? this.project.county.county_name_de : this.project.county.county_name_en
      }
      this.compareValues()
      if (this.$store.state.groups.includes('project_manager')) this.editable = true
      console.log(this.$i18n)
    },
  },
}
</script>
