<template>
  <v-dialog v-model="show"
            max-width="350"
            @click:outside="$emit('closed')"
            @close="$emit('closed')"
  >

    <v-card v-if="showFormCard">
      <v-card-title>Create Vacation</v-card-title>
      <v-card-text>
        <v-autocomplete v-if="this.$store.state.currentUser.groups.includes('vacation_manager') && !userId"
            v-model="user"
            :search-input.sync="search"
            :items="userItems"
            item-text="name"
            item-value="id"
            :label="$tc('user',1)"
        >
        </v-autocomplete>
        <v-select
            v-model="type"
            :items="typeOptions"
            item-value="value"
            item-text="name"
            :label="$t('type')"
        ></v-select>
        <v-text-field
            :label="$t('vacation-create-dialog.start')"
            v-model="start"
            @click="showDatePickerStart=true"
        ></v-text-field>
        <v-text-field
            :label="$t('vacation-create-dialog.end')"
            v-model="end"
            @click="showDatePickerEnd=true"
        ></v-text-field>
        <v-textarea
            :label="$t('note')"
            v-model="note"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="success" @click="submit">{{$t('vacation-create-dialog.submit')}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <!--date picker start -->
    <v-date-picker v-if="showDatePickerStart"
                   v-model="start"
                   :allowed-dates="disablePastDates"
                   @change="showDatePickerStart=false"
                   @update:active-picker="showFormCard=false"
    >
    </v-date-picker>

    <!--date picker start -->
    <v-date-picker v-if="showDatePickerEnd"
                   v-model="end"
                   :allowed-dates="disableDatesBeforeStartDate"
                   @change="showDatePickerEnd=false"
                   @update:active-picker="showFormCard=false"
    >
    </v-date-picker>
  </v-dialog>
</template>

<script>
import {Vacation} from "@/utils/vacation";
import api from "@/ApiFactory"

export default {
  name: 'CreateVacationDialog',
  props: ['show', 'startDate', 'userId'],
  data() {
    return {
      typeOptions: [{name: this.$t('vacation-type.paid-vacation'), value: Vacation.TYPE_PAID},
        {name: this.$t('vacation-type.unpaid-vacation'), value: Vacation.TYPE_UNPAID}],
      type: Vacation.TYPE_PAID,
      start: '',
      end: '',
      note: '',
      showDatePickerStart: false,
      showDatePickerEnd: false,
      showFormCard: true,
      // Autocomplete
      user: null,
      search: null,
      userItems: null
    }
  },
  methods: {
    submit() {
      let vacation = new Vacation()
      vacation.start = this.start
      vacation.end = this.end
      vacation.type = this.type
      vacation.user = (this.userId) ? this.userId : this.user
      vacation.note = this.note
      this.$emit('create', vacation)
      this.$emit('closed')
    },
    disablePastDates(val) {
      return val >= new Date().toISOString()
    },
    disableDatesBeforeStartDate(val) {
      if (this.disablePastDates(val)) {
        return val >= this.start
      } else {
        return false
      }
    },
  },
  watch: {
    'startDate'() {
      this.start = this.startDate
      this.end = this.startDate
    },
    'show'() {
      this.showDatePickerEnd = false
      this.showDatePickerStart = false
    },
    showDatePickerStart(value) {
      if (value) {
        this.showDatePickerEnd = false
        this.showFormCard = false
      } else {
        this.showFormCard = true
      }
    },
    showDatePickerEnd(value) {
      if (value) {
        this.showDatePickerStart = false
        this.showFormCard = false
      } else {
        this.showFormCard = true
      }
    },
    search(value) {
      api.guards.list({q: value}).then(repsonse => {
        this.userItems = repsonse.data.results.map(function (element) {
          return {name: element.first_name + ' ' + element.last_name, id: element.user}
        })
      })
    }
  },
}
</script>