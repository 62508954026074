import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/clients/client/', {
            params: params
        })
    },
    create( data ) {
        return axios.post(`/clients/client/`, data)
    },
    retrieve( id, params ) {
        return axios.get(`/clients/client/${id}`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.patch(`/clients/client/${id}`, data)
    }
}
