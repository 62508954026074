<template>
  <base-card-component
      :title="title"
      :show-title="showTitle"
      :show-title-divider="false"
      :style="cursorStyle"
      @click.native="navigateToVacationList"
  >
    <template v-slot:default>
      <v-list>
        <v-list-item-group v-model="vacations">
          <v-list-item v-for="(item) in vacations" v-bind:key="item.id" three-line>
            <v-list-item-content @click="navigateToVacation(item.id)">
              <v-list-item-subtitle>
                <v-chip label
                        small
                        :color="Vacation.COLOR_PENDING"
                        v-if="item.status===Vacation.STATUS_PENDING"
                        class="mr-1"
                >Pending</v-chip>
                <v-chip label
                        small
                        :color="Vacation.COLOR_APPROVED"
                        v-else-if="item.status===Vacation.STATUS_APPROVED"
                        class="mr-1"
                >Approved</v-chip>
                <v-chip label
                        small
                        :color="Vacation.COLOR_REJECTED"
                        v-else-if="item.status===Vacation.STATUS_REJECTED"
                        class="mr-1"
                >Rejected</v-chip>
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.get_type_display }} {{ item.get_full_name }}
              </v-list-item-title>
              <v-list-item-subtitle><b>{{ relDate(item.start) }}</b></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </base-card-component>
</template>

<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent.vue";
import api from "@/ApiFactory";
import {Vacation} from "@/utils/vacation";
import {DateTime} from "luxon";

export default {
  name: "VacationDashboardComponent",
  computed: {
    Vacation() {
      return Vacation
    }
  },
  components: {BaseCardComponent},
  props: {
    title: {
      default: 'Upcoming Vacations',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    },
    numberOfElements: {
      type: Number,
      default: 5,
      required: false
    }
  },
  data() {
    return {
      vacations: null,
      cursorStyle: {cursor: 'pointer'}
    }
  },
  methods: {
    relDate(created_at) {
      return DateTime.fromISO(created_at).toRelative()
    },
    navigateToVacation(e) {
      this.$router.push({name: 'vacation-details', params: {id: e.toString()}})
    },
    navigateToVacationList() {
      this.$router.push({name: 'vacation-list'})
    },
  },
  mounted() {
    let options = {rejected: false, pending: true, approved: true, json: true}
    options.from_date = new Date().toISOString().substring(0, 10)
    //options.to_date = new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().substring(0, 10)
    api.vacations.list(options).then(response => {
      if (response.data.count < this.numberOfElements) {
        this.vacations = response.data.results.slice(0, response.data.count)
      } else this.vacations = response.data.results.slice(0, this.numberOfElements)
    })
  }
}
</script>