<template>
  <v-container v-if="id">
    <vacation-details-component :id="id"
                                @changed="refresh"
    >
    </vacation-details-component>
  </v-container>
</template>

<script>
import VacationDetailsComponent from "@/components/VacationDetailsComponent";

export default {
  name: 'VacationDetails',
  props: ['id'],
  components: {VacationDetailsComponent},
  methods: {
    refresh(id) {
      this.$router.push({name: 'vacation-details', params: {id: id.toString()}})
    }
  }
}
</script>