import axios from "@/plugins/axios-config";

export default {
    changeDayNumber(id, data) {
        return axios.post(`availability/vacationaccount/${id}/booking/`, data)
    },
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/availability/vacationaccount/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/availability/vacationaccount/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/availability/vacationaccount/${id}`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/availability/vacationaccount/${id}`, data)
    }
}
