<template>
  <v-card flat>
    <v-sheet
        tile
        height="54"
        class="d-flex"
        v-if="is_mounted"
    >
      <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="$refs.calendar" class="ma-3">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :start="start"
          :end="end"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :locale="$i18n.locale"
          @click:event="editEvent"
          @click:date="createEvent"
          @change="calendarChanged"
      >
        <template #day="{day, date}" v-if="holidays">
          <v-chip small v-if="isHoliday(date)">{{isHoliday(date)}}</v-chip>
        </template>
<!--        <template v-slot:day-label="{ day,future }">-->
<!--          <button v-if="future" type="button"-->
<!--                  class="v-btn v-btn&#45;&#45;fab v-btn&#45;&#45;has-bg v-btn&#45;&#45;round theme&#45;&#45;light v-size&#45;&#45;small transparent"-->
<!--          >-->
<!--            <span class="v-btn__content">{{ day }}</span>-->
<!--          </button>-->
<!--          <button v-else type="button"-->
<!--                  class="v-btn v-btn&#45;&#45;fab v-btn&#45;&#45;has-bg v-btn&#45;&#45;round theme&#45;&#45;light v-size&#45;&#45;small transparent">-->
<!--            <span class="v-btn__content">{{ day }}</span>-->
<!--          </button>-->
<!--        </template>-->
      </v-calendar>
    </v-sheet>
  </v-card>
</template>


<script>
export default {
  props: {
    events: null,
    start: null,
    end: null,
    holidays: {
      type: Array,
      default: null
    }
},
  data: () => ({
    type: 'month',
    mode: 'stack',
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: '',
    now: Date.now(),
    is_mounted: false
  }),
  mounted() {
    this.is_mounted = true
  },
  methods: {
    editEvent({event, timed}) {
      this.$emit('clickedEvent', event, timed)
    },
    createEvent(dateTime, mouseEvent) {
      this.$emit('createEvent', dateTime, mouseEvent)
    },
    calendarChanged({start, end}) {
      this.$emit('calendarChanged', start, end)
    },
    isHoliday(date){
      if (this.holidays.find(element => element.day === date)) {
        const holiday = this.holidays.find(element => element.day === date)
        return holiday.name
      }
    }
  }
}

</script>