<template>
  <v-app>
    <v-main app>

      <ToastComponent ref="ToastComponent"></ToastComponent>

      <AppHeader v-if="isAuthenticated" @toggleSidebar="toggleSideBar"></AppHeader>

      <v-navigation-drawer app v-if="isAuthenticated" v-model="drawer" :permanent="permanent">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Guardport
            </v-list-item-title>
            <v-list-item-subtitle>
              Main menu
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list>
          <v-list-item link exact :to="{name: 'home'}" @click="drawer=!drawer">
            <v-icon large>mdi-home</v-icon>
            {{ $t('home') }}
          </v-list-item>
          <v-list-item v-if="groups.includes('vacation_manager')"
                       link
                       :to="{name: 'guards'}"
                       @click="drawer=!drawer">
            <v-icon large>mdi-account-multiple</v-icon>
            {{ $tc('Guard', 2) }}
          </v-list-item>
          <v-list-item v-if="groups.includes('vacation_manager')"
                       link
                       :to="{name: 'vacation-list'}"
                       @click="drawer=!drawer">
            <v-icon large>mdi-calendar-account</v-icon>
            {{ $tc('Vacation', 2) }}
          </v-list-item>
          <v-list-item v-if="groups.includes('project_manager') || groups.includes('project_reader')"
                       link
                       :to="{name: 'projects'}"
                       @click="drawer=!drawer">
            <v-icon large>mdi-format-list-bulleted</v-icon>
            {{ $tc('Project', 2) }}
          </v-list-item>
          <v-list-item v-if="groups.includes('alerts_manager') || groups.includes('alerts_user')"
                       link
                       :to="{name: 'verkada-alerts'}"
                       @click="drawer=!drawer">
            <v-icon large>mdi-alert-box</v-icon>
            &nbsp; {{ $tc('Alert', 2) }}
          </v-list-item>

          <v-list-item v-if="groups.includes('logbook_manager') || groups.includes('logbook_user')"
                       link
                       :to="{name: 'logbook'}"
                       @click="drawer=!drawer">
            <v-icon large>mdi-book</v-icon>
            &nbsp; {{ $tc('Logbook', 1) }}
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Automatic Breadcrumb -->
      <v-breadcrumbs v-if="isAuthenticated" :items="breadcrumbItems"></v-breadcrumbs>

      <!-- Router anchor point -->
      <router-view></router-view>

      <AppFooter></AppFooter>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import ToastComponent from '@/components/ToastComponent.vue'
import BaseButtonComponent from "@/components/templates/BaseButtonComponent";
import {CHECK_AUTH} from "../store/action-types";
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {AppHeader, AppFooter, ToastComponent, BaseButtonComponent},
  data() {
    return {
      drawer: !this.$vuetify.breakpoint.mobile,
      permanent: !this.$vuetify.breakpoint.mobile,
      breadcrumbItems: []
    }
  },
  methods: {
    toggleSideBar() {
      this.drawer = !this.drawer
      this.permanent = !this.permanent
    }
  },
  mounted: function () {
    document.title = 'Guardport'

    // init Toast Component
    this.$root.vtoast = this.$refs.ToastComponent

    // reload User object if it's not accessible
    // this happens when user reloads the page,
    // because the store which resides only in memory is deleted
    if (!this.currentUser.groups) {
      this.$store.dispatch(CHECK_AUTH)
    }
  },
  computed: {
    ...mapState(["currentUser", "isAuthenticated", "alerts_enabled", "groups"]),
  },
  watch: {
    '$route'() {
      // Obtain breadcrumb item list from changed $route object
      // noinspection JSUnresolvedVariable
      if (this.$route.meta.breadcrumb) {
        this.breadcrumbItems = this.$route.meta.breadcrumb.map(item => {
          return {text: item.text, to: item.to, exact: true, disabled: (item.disabled) ? item.disabled : false}
        })
      }
    }
  }
};
</script>
