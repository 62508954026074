import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, retrieve, create, update, delete)
    list() {
        return axios.get('/core/settings/')
    },
    retrieve( id, params ) {
        return axios.get(`/core/settings/${id}`, {
            params: params
        })
    }
}
