import {parseCookie} from "@/assets/js/utils";

const ACCESS_TOKEN = "accessToken";

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

export const getTokenfromCookie = () => {
    let cookie = document.cookie
    if (cookie) {
        let parsedCookie = parseCookie(document.cookie)
        if ('token' in parsedCookie) {
            saveToken(parsedCookie.token)
            return parsedCookie.token
        }
    }
}

export const saveToken = token => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
  // for backwards compatibility we set the token also in a cookie
  document.cookie = "token=" + token
};

export const purgeToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);

  // remove token cookie
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"

};

// for possible later use of JWT
// function getLocalRefreshToken() {
//     return window.localStorage.getItem("refreshToken");
// }

export default { getToken, saveToken, purgeToken };
