// Defines a contructor for a User
import {User} from "@/utils/users";
import {VacationType} from "@/utils/vacation_type";

export class Vacation {
    constructor(id = null,
                start = null,
                end = null,
                note = '',
                user = null,
                type = Vacation.TYPE_PAID,
                status = 0,
                get_status_display = '',
                get_type_display = '',
                get_full_name = '',
                requested_at = null,
                reviewed_by = '',
                reviewed_at = null,
                review_note = ''
    ) {
        this.id = id
        this.start = start
        this.end = end
        this.user = user
        this.note = note
        this.type = type
        this.name = (type) ? type.shortname : ''
        this.color = this.#get_color()
        this.status = status
        this.get_status_display = get_status_display
        this.get_type_display = get_type_display
        this.get_full_name = get_full_name
        this.requested_at = requested_at
        this.reviewed_by = reviewed_by
        this.reviewed_at = reviewed_at
        this.review_note = review_note
    }

    #get_color() {
        if (this.status === Vacation.STATUS_PENDING) {
            return Vacation.COLOR_PENDING
        } else if (this.status === Vacation.STATUS_APPROVED) {
            return Vacation.COLOR_APPROVED
        } else {
            return Vacation.COLOR_REJECTED
        }
    }

    read_data_from_json(data, user) {
        this.id = data.id
        this.start = new Date(data.start)
        this.end = new Date(data.end)
        this.user = data.user
        this.note = data.note
        this.type = data.type
        this.status = data.status
        this.get_status_display = data.get_status_display
        this.get_type_display = data.get_type_display
        this.get_full_name = data.get_full_name
        this.requested_at = new Date(data.requested_at)
        this.reviewed_by = data.reviewed_by
        this.reviewed_at = (data.reviewed_at) ? new Date(data.reviewed_at) : null
        this.review_note = data.review_note
        this.name = this.get_full_name + ' - ' + this.get_type_display
        this.color = this.#get_color()
    }

    get_json() {
        console.log(this)
        const data = {
            id: this.id,
            start: this.start.toISOString(),
            end: this.end.toISOString(),
            note: this.note,
            user: this.user.id,
            type: this.type,
            status: this.status,
            get_status_display: this.get_status_display,
            get_type_display: this.get_type_display,
            get_full_name: this.get_full_name,
            requested_at: this.requested_at.toISOString(),
            reviewed_by: this.reviewed_by,
            reviewed_at: this.reviewed_at.toISOString(),
            review_note: this.review_note,
        }
        console.log(JSON.stringify(data))
        return JSON.stringify(data)
    }

    static getObjectFromJSON(data) {
        let vacation =  new Vacation()
        vacation.read_data_from_json(data)
        return vacation
    }

    static STATUS_PENDING = 0
    static STATUS_APPROVED = 1
    static STATUS_REJECTED = 2
    static STATUS_PRE_APPROVED = 3

    static COLOR_PENDING = 'orange'
    static COLOR_APPROVED = 'green'
    static COLOR_REJECTED = 'red'
    static COLOR_PRE_APPROVED = 'yellow'

    static TYPE_PAID = 1
    static TYPE_UNPAID = 0

    static get_color (status) {
        if (status === Vacation.STATUS_PENDING) {
            return Vacation.COLOR_PENDING
        } else if (status === Vacation.STATUS_APPROVED) {
            return Vacation.COLOR_APPROVED
        } else {
            return Vacation.COLOR_REJECTED
        }
    }

    // Convert API vacation object to vuetify event object (extended by extra fields)
    static get_event_obj(vacation) {
        let event = vacation
        event.start = new Date(vacation.start)
        event.end = new Date(vacation.end)
        event.requested_at = new Date(vacation.requested_at)
        event.reviewed_at = (vacation.reviewed_at) ? new Date(vacation.reviewed_at) : null
        event.color = this.get_color(vacation.status)
        event.name = vacation.get_full_name + ' - ' + vacation.get_type_display
        return event
    }


}