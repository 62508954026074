<template>
  <base-card-component :title="title" :show-title="showTitle">
    <template #action>
      <slot name="action"></slot>
      <BaseButtonComponent v-if="showSaveButton" @click="$emit('save')" :disabled="saveButtonDisabled">Save</BaseButtonComponent>
    </template>

    <template #default>
      <slot name="default"></slot>
    </template>

  </base-card-component>
</template>

<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent.vue";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent.vue";

export default {
  name: 'GenericDetailsComponent',
  components: {BaseCardComponent, BaseButtonComponent},
  emits: ['save'],
  props: {
    title: {
      default: '',
      type: String
    },
    showTitle: {
      default: false,
      type: Boolean
    },
    showSaveButton: {
      default: false,
      type: Boolean
    },
    saveButtonDisabled: {
      default: false,
      type: Boolean
    }
  },
}

</script>