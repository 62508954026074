import axios from "@/plugins/axios-config";

export default {
    get_new_count( params ) {
        return axios.get('/alerts/alert/get_new_alerts_count/', {
            params: params
        })
    },
    // default endpoints (list, retrieve, create, update, delete)
    list( params ) {
        return axios.get('/alerts/alert/', {
            params: params
        })
    },
    retrieve( id, params ) {
        return axios.get(`/alerts/alert/${id}`, {
            params: params
        })
    }
}
