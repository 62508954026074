import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/users/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/users/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/users/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/users/${id}/`, data)
    }
}
