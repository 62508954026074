<template>
  <v-container fluid>
    <v-tabs>
      <v-tab>Info</v-tab>
      <v-tab>
          {{ $tc('guard',2) }}
      </v-tab>
      <v-tab-item>
        <project-details-component v-if="project" :project.sync="project"></project-details-component>
      </v-tab-item>
      <v-tab-item :eager="true">
      <project-guard-table-component :project="id"
                                     :show-title="false"
                                     :memberships.sync="members"
      >
      </project-guard-table-component>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import ProjectDetailsComponent from "@/components/ProjectDetailsComponent";
import MembershipDetailsComponent from "@/components/MembershipDetailsComponent";
import {Project} from "@/utils/project";
import MembershipTableComponent from "@/components/MembershipTableComponent";
import api from "@/ApiFactory";
import ProjectGuardTableComponent from "@/components/ProjectGuardTableComponent.vue";


export default {
  components: {ProjectGuardTableComponent, MembershipTableComponent, MembershipDetailsComponent, ProjectDetailsComponent},
  props: ['id'],
  data() {
    return {
      project: new Project(),
      members: []
    }
  },
  methods: {
    fetchProject() {
      this.$store.commit('activateLoading')
      api.projects.retrieve(this.id).then(
          response => {
            this.project = response.data
            this.$store.commit('deactivateLoading')
          }
      )
    }
  },
  mounted() {
    this.fetchProject()
  }
}
</script>