import axios from "@/plugins/axios-config";

export default {
    latest( params ) {
        return axios.get('/logbook/logbookentry/get_latest/', {
            params: params
        })
    },
    history( id, params ) {
        return axios.get(`/logbook/logbookentry/${id}/get_history/`, {
            params: params
        })
    },
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/logbook/logbookentry/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/logbook/logbookentry/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/logbook/logbookentry/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/logbook/logbookentry/${id}/`, data)
    },
}
