<template>
  <base-card-component :title="$tc('Settings')" :show-title="true">
    <template #default>
      <v-form ref="settingsForm" v-model="valid">
        <v-row>
          <v-col md="4">
            <v-list>
              <v-list-item-subtitle>
                <v-select
                    v-model="prefs.ui_lang"
                    :label="$t('language')"
                    :items="languageOptions"
                    item-value="value"
                    item-text="name"
                    @change="isDirty=true"
                >
                </v-select>
              </v-list-item-subtitle>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-switch v-model="prefs.ui_dark" :label="$t('Dark mode enabled')" @change="isDirty=true"></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-spacer></v-spacer>
<!--      TODO: activate reset to default function-->
<!--      <v-btn plain @click="">{{ $tc('Reset') }}</v-btn>-->
      <base-button-component :disabled="!isDirty" @click="submitForm">{{ $tc('Save')}}</base-button-component>
    </template>
  </base-card-component>
</template>

<script>

import BaseCardComponent from "@/components/templates/BaseCardComponent";
import api from "@/ApiFactory";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent.vue";
import {mapState} from "vuex";

export default {
  components: {BaseButtonComponent, BaseCardComponent},
  data() {
    return {
      valid: false,
      isDirty: false,

      prefs: {
        ui_lang: 'en',
        ui_dark: false
      },

      languageOptions: [{name: this.$t('languages.english'), value: 'en'},
        {name: this.$t('languages.german'), value: 'de'}],
    }
  },
  methods: {
    submitForm() {
      const validated = this.$refs.settingsForm.validate()
      if (validated) {
        this.$store.commit("setPreferences", this.prefs)
        this.$store.commit('activateLoading')
        api.userpreferences.update(this.currentUser.id, this.prefs)
            .then(() => {
              this.$root.vtoast.show({message: this.$tc('Successfully updated')})
              this.$root.$i18n.locale = this.prefs.ui_lang
              this.$vuetify.theme.dark = this.prefs.ui_dark
              this.isDirty = false
            }
            ).catch(error => {
              this.errorMessage = error
            })
            this.$store.commit('deactivateLoading')
      }
    },
  },
  computed: {
    ...mapState(["currentUser"]),
  },
  mounted() {
      api.userpreferences.retrieve(this.currentUser.id)
          .then(response => {
            this.prefs = response.data
          })
    },
}
</script>
