<template>
  <v-card v-if="guard">
    <v-card-title>
      {{guard.first_name}} {{guard.last_name}}
      <v-spacer></v-spacer>
      <v-chip v-if="membership.is_manager">{{$t('membership.project-leader')}}</v-chip>
      <v-chip v-if="membership.is_reader">reader</v-chip>
      <v-chip v-if="membership.is_main">{{$t('membership.main-project')}}</v-chip>
    </v-card-title>
  </v-card>
</template>

<script>
//import {fetchGuard} from "@/services/guard_service";
import api from "@/ApiFactory"


export default {
  props: ['membership'],
  data() {
    return {
      guard: null,
      project: null
    }
  },
  mounted() {
    api.guards.retrieve(this.membership.guard).then(guard => this.guard)
    // fetchGuard(this.$api_url, this.$store.state.token, this.membership.guard).then(guard => {
    //   this.guard = guard
    // })
  }
}
</script>
