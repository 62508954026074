// base axios config
import axios from "axios"
import {getToken, getTokenfromCookie} from "@/utils/token_service";


const BASE_URL = process.env.VUE_APP_API_ROOT

const instance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    },
    baseURL: BASE_URL
});

instance.interceptors.request.use(function(config) {
        const token = getToken()
        if (!token) {
            const token = getTokenfromCookie()
        }
        if (token) {
            config.headers["Authorization"] = `Token ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;
//
//     if (err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//
//         try {
//           const rs = await refreshToken();
//           const { accessToken } = rs.data;
//           window.localStorage.setItem("accessToken", accessToken);
//           instance.defaults.headers.common["x-access-token"] = accessToken;
//
//           return instance(originalConfig);
//         } catch (_error) {
//           if (_error.response && _error.response.data) {
//             return Promise.reject(_error.response.data);
//           }
//
//           return Promise.reject(_error);
//         }
//       }
//
//       if (err.response.status === 403 && err.response.data) {
//         return Promise.reject(err.response.data);
//       }
//     }
//
//     return Promise.reject(err);
//   }
// );

export default instance
