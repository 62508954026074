// Definition of model class projects

export class Project {

    constructor(id = null,
                shortname = '',
                description = '',
                country= null,
                county =null,
                tz = '',
                client = null,
                client_shortname = '',
                is_active = false,
                members = []
    ) {
        this.id = id
        this.shortname = shortname
        this.description = description
        this.country = country
        this.county = county
        this.is_active = is_active
        this.client = client
        this.members = members
    }
}