<template>
  <base-card-component :title="title" :show-title="showTitle">
    <!-- BaseCardComponent action slot -->
    <template #action>
      <slot name="action"></slot>
    </template>
    <!-- BaseCardComponent default slot  -->
    <template #default>
      <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          :disable-sort="true"
          :items-per-page="itemsPerPage"
          :disable-pagination="true"
          :footer-props="footerProps"
          :server-items-length="itemList.count"
          :show-select="showSelect"
          @click:row="e => rowClickable && forwardToObject(e)"
          @pagination="paginationChanged"
      >
        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData"/>
        </template>
        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName"/>
        </template>
        <!-- No Data Slots -->
        <template v-slot:no-data>{{ $t('No data available') }}</template>
        <template v-slot:no-results>{{ $t('No search result') }}</template>
      </v-data-table>
    </template>
  </base-card-component>
</template>

<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent";

export default {
  components: {BaseCardComponent, BaseButtonComponent},
  props: {
    title: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: []
    },
    refreshListFunc: {
      type: Function,
    },
    objectForwardFunc: {
      type: Function,
      default: null
    },
    search: {
      type: Object,
      default: null
    },
    trigger: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    rowClickable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      itemList: {count: 0, results: []},
      itemsPerPage: 50,
      footerProps: {
        'disable-items-per-page': true,
        'items-per-page-text': '',
        'show-first-last-page': true,
        'items-per-page-options': [10, 50, 100],
      }
    }
  },
  computed: {
    // Computes item list dynamically based on props itemList and headers
    items() {
      return this.itemList.results
    }
  },
  methods: {
    refresh(page) {
      this.$store.commit('activateLoading')
      this.refreshListFunc(page).then(list => {
        this.itemList = list
        this.$store.commit('deactivateLoading')
      })
    },
    forwardToObject(e) {
      this.objectForwardFunc(e)
    },
    paginationChanged(e) {
      this.refresh(e.page)
    },
    emitSearchValue(e) {
      this.$emit('searchValueChanged', e)
    },
    emitSearchInput(e) {
      this.$emit('searchInput', e)
    },
  },
  watch: {
    itemList() {
      if (this.itemList.next) {
          this.itemsPerPage = this.itemList.results.length
        }
    },
    '$route'(to, from) {
      this.refresh(1)
    },
    search() {
      this.refresh(1)
    },
    trigger() {
      if(this.trigger) {
        this.refresh()
        this.$emit('update:trigger', false)
      }
    },
  },
}
</script>
