import api from "@/ApiFactory";
import {SET_NEW_ALERTS_COUNT} from "../mutation-types";
import {FETCH_NEW_ALERTS_COUNT} from "../action-types";

const alerts = {
    namespaced: true,

    state: {
        new_alerts_count: 0,
        // Object
        count: 0,
        id: null,
        client: 0,
        alert_type: '',
        alert_status: '',
        created_by: null,
        created_by_fullname: '',
        created_at:null,        
    },
    mutations: {
        [SET_NEW_ALERTS_COUNT] (state, payload) {
            state.new_alerts_count = payload
        }
    },
    actions: {
        [FETCH_NEW_ALERTS_COUNT](context) {
            api.alerts.get_new_count()
                .then(response => {
                    context.commit(SET_NEW_ALERTS_COUNT, response.data.result)
                })
        }
    },
    getters: {

    }
}

export default alerts
