<template>
  <base-card-component :show-title="true">
    <!-- Custom Title -->
    <template #title>
      <v-list-item two-line>
        <v-list-item-avatar
            size="80"
            color="blue"
        >
          <v-list-item-content class="justify-center white--text text-h5">
            {{ initials }}
          </v-list-item-content>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ guard.title }} {{ guard.first_name }} {{ guard.last_name }}</v-list-item-title>
          <v-list-item-subtitle>{{ guard.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <!-- Custom Content -->
    <template #default>
      <v-tabs centered>
        <v-tab>{{ $t('General') }}</v-tab>
        <v-tab>{{ $t('More') }}</v-tab>
        <v-tab-item>
          <v-list>
            <v-select v-model="guard.gender"
                      :label="$t('gender')"
                      :items="genderItems"
                      :readonly="!isManager"
                      @change="changesMade=true"
            ></v-select>
            <v-text-field v-model="guard.nationality"
                          :label="$t('nationality')"
                          :readonly="!isManager"
                          @change="changesMade=true"
            ></v-text-field>
            <v-text-field v-model="guard.dob"
                          :label="$t('dob')"
                          :readonly="!isManager"
                          @change="changesMade=true"
            ></v-text-field>
            <v-text-field v-model="guard.email"
                          :label="$t('email')"
                          :readonly="!isManager"
                          @change="changesMade=true"
            ></v-text-field>
            <v-text-field v-model="guard.address"
                          :label="$t('address')"
                          :readonly="!isManager"
                          @change="changesMade=true"
            ></v-text-field>
            <v-text-field v-model="guard.birth_place"
                          :label="$t('birth place')"
                          :readonly="!isManager"
                          @change="changesMade=true"
            ></v-text-field>
            <v-text-field v-model="guard.birth_country"
                          :label="$t('birth country')"
                          :readonly="!isManager"
                          @change="changesMade=true"
            ></v-text-field>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-switch v-model="guard.is_active"
                    :label="$t('is_active')"
                    :disabled="!isManager"
                    @change="changesMade=true"
          ></v-switch>
          <v-switch v-model="guard.is_active_guard"
                    :label="$t('is_active_guard')"
                    :disabled="!isManager"
                    @change="changesMade=true"
          ></v-switch>
          <v-switch v-model="guard.is_employee"
                    :label="$t('is_employee')"
                    :disabled="!isManager"
                    @change="changesMade=true"
          ></v-switch>
          <v-select v-model="guard.favorite_lang"
                    :label="$t('favorite-lang')"
                    :items="langItems"
                    :readonly="!isManager"
                    @change="changesMade=true"
          ></v-select>
          <v-select v-model="guard.weekly_workdays"
                    :label="$t('weekly-workdays')"
                    :items="weeklyWorkdaysItems"
                    :readonly="!isManager"
                    @change="changesMade=true"
          ></v-select>
          <v-select v-model="guard.daily_workhours"
                    :label="$t('daily-workhours')"
                    :items="dailyWorkhoursItems"
                    :readonly="!isManager"
                    @change="changesMade=true"
          ></v-select>
        </v-tab-item>
      </v-tabs>
    </template>
    <!-- Custom Footer -->
    <template #footer v-if="isManager">
      <v-spacer></v-spacer>
      <base-button-component @click="updateGuard" :disabled="!changesMade">Save Changes</base-button-component>
    </template>
  </base-card-component>
</template>

<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent";
import BaseAlertComponent from "@/components/templates/BaseAlertComponent";
import api from "@/ApiFactory"

export default {
  components: {BaseAlertComponent, BaseButtonComponent, BaseCardComponent},
  props: {
    guard: {
      type: Object,
    },
    isManager: {
      type: Boolean,
      default: false,
      originalGuard: null
    }
  },
  data() {
    return {
      editMode: false,
      genderItems: ['M', 'F', 'D'],
      langItems: ['en', 'de'],
      weeklyWorkdaysItems: [1, 2, 3, 4, 5, 6],
      dailyWorkhoursItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      changesMade: false,
    }
  },
  computed: {
    initials() {
      const f1 = this.guard.first_name.substring(0, 1).toUpperCase()
      const f2 = this.guard.last_name.substring(0, 1).toUpperCase()
      return f1 + f2
    }
  },
  methods: {
    routeToUsersVacationView() {
      this.$router.push({name: "vacations", query: {user_id: this.guard.user}})
    },
    updateGuard() {
      this.changesMade = false
      api.guards.update(this.guard.id, this.guard)
      //updateGuard(this.$api_url, this.$store.state.token, this.guard.id, this.guard)
    }
  }
}
</script>

<style>

.custom-card-item {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
}

</style>
