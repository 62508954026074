<template>
  <v-container app fluid>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <!-- Welcome Card -->
        <v-card v-if="groups.includes('vacation_manager')" @click="navigateToUserDetails">
          <v-card-title>{{ $t('hello')}} {{ person.first_name }} {{ person.last_name }}</v-card-title>
          <v-card-subtitle>{{ $tc('vacation',2)}}, {{ $t('personal details') }}, {{ $t('documents') }} {{ $t('etc.') }}</v-card-subtitle>
          <v-card-text>
            You are logged in with username: <b><i>{{ currentUser.username }}</i></b>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>{{ $t('hello')}} {{ person.first_name }} {{ person.last_name }}</v-card-title>
          <v-card-text>
            You are logged in with username: <b><i>{{ currentUser.username }}</i></b>
          </v-card-text>
        </v-card>

        <!-- Upcoming vacations -->
        <vacation-dashboard-component v-if="groups.includes('vacation_manager')"
                                      :title="$t('Upcoming vacations')"
                                      :number-of-elements="3"
                                      class="mt-4"
        ></vacation-dashboard-component>
      </v-col>

      <!-- Latest logbook entries -->
      <v-col cols="12" md="6" sm="12">
        <logbook-dashboard-component v-if="groups.includes('logbook_user') || groups.includes('logbook_manager')"
                                     :title="$t('Latest logbook entries')"
        ></logbook-dashboard-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" sm="12">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import VacationDashboardComponent from "@/components/vacation/VacationDashboardComponent.vue";
import LogbookDashboardComponent from "@/components/logbook/LogbookDashboardComponent.vue";

export default {
  name: 'Home',
  components: {LogbookDashboardComponent, VacationDashboardComponent, BaseCardComponent},
  data() {
    return {
      numberOfOpenVacations: 0,
      logbookLatest: null,
      created: null,
    }
  },
  methods: {
    navigateToUserDetails () {
      this.$router.push({name: 'guard-details', params: {id: this.currentUser.person.id.toString()}})
    },
  },
  mounted () {
    document.title = 'Guardport Home'
  },
  computed: {
    ...mapState(["currentUser", "isAuthenticated", "loading", "person", "groups"]),
  }
}
</script>
