// Definition of model class for logbook

export class Logbook {

    constructor(id = null,
                category = 0,
                get_category_display = '',
                logtext = '',
                created_by = null,
                created_by_fullname = '',
                created_at =null,
                time_of_occurence = null,
                duration = null,
                client = 0,
                client_shortname = null,
                project = null,
                project_shortname = '',
                project_timezone = '',
                alert = null,
                alert_type = 0,
                get_alert_type_display = ''
    ) {
        this.id = id
        this.category = category
        this.get_category_display = get_category_display
        this.logtext = logtext
        this.created_by = created_by
        this.created_by_fullname = created_by_fullname
        this.created_at = created_at
        this.time_of_occurence = time_of_occurence
        this.duration = duration
        this.client = client
        this.client_shortname = client_shortname
        this.project = project
        this.project_shortname = project_shortname
        this.project_timezone = project_timezone
        this.alert = alert
        this.alert_type = alert_type
        this.get_alert_type_display = get_alert_type_display
    }

    // Entry type constants
    static ENTRY_TYPE_DEFAULT = 0
    static ENTRY_TYPE_STICKY = 1
    static ENTRY_TYPE_PHONE_CALL_INBOUND = 2
    static ENTRY_TYPE_PHONE_CALL_OUTBOUND = 3

}
