import {SET_LATEST_LOGBOOK_COUNT} from "../mutation-types";
import api from "@/ApiFactory";

const logbook = {
    namespaced: true,

    state: {
        latest: 0,
        // Object
    },
    mutations: {
        [SET_LATEST_LOGBOOK_COUNT] (state, payload) {
            state.latest = payload
        }
    },
    actions: {
        fetchLatest(context) {
            api.logbook.latest()
                .then(response => {
                    context.commit(SET_LATEST_LOGBOOK_COUNT, response.data)
                })
        }
    },
    getters: {

    }
}

export default logbook
