<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
        <div v-html="markdownToHtml"></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {marked} from "marked";
import changelog from '/CHANGELOG.md';
export default {
  name: "ChangeLog",

  data() {
    return {
      isAdmin: false
    }
  },
  computed: {
    markdownToHtml() {
      marked.setOptions({
        gfm: true,
        breaks: true
      })
      return marked(changelog)
    }
  } ,
  mounted () {
    document.title = 'Guardport Changelog'
  },
}
</script>

<style>
div h3 { margin-top: 25px; }
div h4 { margin-top: 5px; }
</style>
