<template>
  <v-container fluid>
    <base-data-table-component :title="title"
                               :show-title="true"
                               :headers="headers"
                               :refresh-list-func="refreshListFunc"
                               :object-forward-func="forwardToDetailView"
                               :search="filterObj"
                               :trigger.sync="triggerRefresh"
    >

       <template v-slot:top>
         <v-row>
           <!-- Filtering Projects -->
        <v-col cols="12" md="4" lg="4" xl="4">
          <FilterComponent
              v-model="clientValue"
              :items="clientFilterItems"
              :label="$t('Client')"
          ></FilterComponent>
        </v-col>
         </v-row>
       </template>


      <template v-slot:item.alert_type="{item}">
        <v-chip label small v-if="item.alert_type===0" color="warning">{{ "UNKNOWN" }}</v-chip>
        <v-chip label small v-else-if="item.alert_type===1" color="info">{{ "NOTIFICATION" }}</v-chip>
        <v-chip label small v-else-if="item.alert_type===2" color="warning">{{ "WARNING" }}</v-chip>
        <v-chip label small v-else-if="item.alert_type===3" color="error">{{ "ALARM" }}</v-chip>
        <v-chip label small v-else-if="item.alert_type===4" color="error">{{ "EMERGENCY" }}</v-chip>
        <v-chip label small v-else-if="item.alert_type===5" color="info">{{ "LPR" }}</v-chip>
        <v-chip label small v-else-if="item.alert_type===6" color="info">{{ "SENSOR" }}</v-chip>
      </template>

      <template v-slot:item.alert_status="{item}">
        <v-chip label small v-if="item.alert_status===0" color="warning">{{ "NEW" }}</v-chip>
        <v-chip label small v-else-if="item.alert_status===1" color="info">{{ "PROGRESS" }}</v-chip>
        <v-chip label small v-else-if="item.alert_status===2" color="warning">{{ "PENDING" }}</v-chip>
        <v-chip label small v-else-if="item.alert_status===3" color="success">{{ "CLOSED" }}</v-chip>
      </template>

      <!-- Customize Datetime fields -->
    <template v-slot:item.created_at="{item}">{{ getISO(item.created_at, item.project_timezone) }}</template>
    <template v-slot:item.created_at_utc="{item}">{{ getUTC(item.created_at) }}</template>

    </base-data-table-component>
  </v-container>
</template>

<script>
import api from "@/ApiFactory";
import BaseDataTableComponent from "@/components/templates/BaseDataTableComponent.vue";
import FilterComponent from "@/components/templates/FilterComponent.vue";
import {DateTime} from "luxon";

export default {
  components: {BaseDataTableComponent, FilterComponent},
  data() {
    return {
      title: this.$tc('Alert', 2),
      clientValue:null,
      clientFilterItems: [],
      triggerRefresh: false
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$tc('Project'), value: 'project_shortname'},
        {text: this.$tc('Client'), value: 'client_shortname'},
        {text: this.$tc('Alert_type'), value: 'alert_type'},
        {text: this.$tc('Alert_status'), value: 'alert_status'},
        {text: this.$tc('Camera'), value: 'camera_name'},
        {text: this.$tc('Created_at') + ' LT', value: 'created_at'},
        {text: this.$tc('Created_at') + ' UTC', value: 'created_at_utc'}
      ]
    },
    filterObj() {
      let filter = {}
      //if (this.searchValue) filter.search = this.searchValue
      if (this.clientValue) filter.client_id = this.clientValue
      return filter
    },
    refreshDataonStateChange: function () {
      return this.$store.state.alerts.new_alerts_count
    }
  },
  methods: {
    async refreshListFunc(page) {
      this.$store.commit('activateLoading')
      let params = {ordering: '-created_at'}
      // set parameters to only show NEW entries
      params.alert_status = 0
      if (page) params.page = page
      for (const [key, value] of Object.entries(this.filterObj)) {
        params[key] = value
      }
      return await api.alerts.list(params).then(
          response => {
            this.$store.commit('deactivateLoading')
            return response.data
          }
      )
    },
    forwardToDetailView(alert) {
      this.$router.push({name: 'verkada-alert-details', params: {id: alert.id.toString()}})
    },
    getISO(dt, tz) {
      // grab the timezone from the projects location
      return DateTime.fromISO(dt, {zone: tz}).toFormat('yyyy-MM-dd TTT')
    },
    getUTC(dt) {
      return DateTime.fromISO(dt).toUTC().toFormat('yyyy-MM-dd TTT')
    }
  },
  mounted() {
    api.clients.list().then(response => {
      this.clientFilterItems = response.data.results.map(item => {
        return {text: item.shortname, value: item.id}
      })
    })
    this.refreshListFunc()
  },
  watch: {
    refreshDataonStateChange(newValue, oldValue) {
      if (oldValue !== newValue) {
        console.log(`old: ${oldValue} new: ${newValue} refresh AlertList...`)
        this.triggerRefresh = true
      }
    }
  }
}
</script>
