<template>
  <base-card-component
      :title="title"
      :show-title="true"
      :show-title-divider="false"
      :style="cursorStyle"
      @click.native="navigateToLogbook"
  >
    <template #default>
      <v-list>
        <v-list-item-group v-model="$store.state.logbook.latest">
          <v-list-item v-for="(item) in $store.state.logbook.latest" v-bind:key="item.id" three-line>
            <v-list-item-content @click="navigateToLogbookEntry(item.id)">
              <v-list-item-subtitle>
                <v-chip label small v-if="item.category===0" class="primary mr-1">{{ $tc('Info') }}</v-chip>
                <v-chip label small v-if="item.category===1" class="warning mr-1">{{ $tc('Notice') }}/{{ $tc('Warning') }}</v-chip>
                <v-chip label small v-if="item.category===2" class="error mr-1">{{ $tc('Danger') }}/{{ $tc('Risk') }}</v-chip>
                <!-- <v-chip label small v-if="item.entry_type===0" class="primary mx-1">{{ $tc('Default') }}</v-chip> -->
                <v-chip label small v-if="item.entry_type===Logbook.ENTRY_TYPE_STICKY" class="primary mr-1">{{ $tc('Sticky') }}</v-chip>
                <v-chip label small v-if="item.entry_type===Logbook.ENTRY_TYPE_PHONE_CALL_INBOUND" class="primary mr-1">
                  <v-icon small left color="white">mdi-phone-incoming</v-icon>
                  {{ $tc('In') }}
                </v-chip>
                <v-chip label small v-if="item.entry_type===Logbook.ENTRY_TYPE_PHONE_CALL_OUTBOUND" class="primary mr-1">
                  <v-icon small left color="white">mdi-phone-outgoing</v-icon>
                  {{ $tc('Out') }}
                </v-chip>
                <v-chip label small v-if="item.camera_name" class="mr-1"><v-icon small left>mdi-cctv</v-icon> {{item.camera_name}}</v-chip>
                <v-chip label small v-if="item.client_shortname" class="mr-1">{{ item.client_shortname }}</v-chip>
                <v-chip label small v-if="item.project_shortname">{{ item.project_shortname }}</v-chip>
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.logtext }}
              </v-list-item-title>
              <v-list-item-subtitle><b>{{ relDate(item.created_at) }}</b> {{ $t('logged by') }} <b>{{ item.created_by_fullname }}</b>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </base-card-component>
</template>

<script>

import BaseCardComponent from "@/components/templates/BaseCardComponent.vue";
import {DateTime} from "luxon";
import {Logbook} from "@/utils/logbook";

export default {
  name: 'LogbookDashboardComponent',
  computed: {
    Logbook() {
      return Logbook
    }
  },
  components: {BaseCardComponent},
  props: {
    title: {
      default: "Latest Logbook Entries",
      required: false
    }
  },
  data () {
   return {
     cursorStyle: { cursor: 'pointer'}
   }
  },
  methods: {
    navigateToLogbook() {
      this.$router.push({name: 'logbook'})
    },
    navigateToLogbookEntry(e) {
      this.$router.push({name: 'logbook-details', params: {id: e.toString()}})
    },
    relDate(created_at) {
      return DateTime.fromISO(created_at).toRelative({locale: this.$i18n.locale})
    },
  }
}

</script>