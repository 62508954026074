<template>
  <v-card flat outlined>
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('vacation-account') }}: {{ tempVacationAccount.remaining_days_total }} {{ $t('avail-total') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-text-field :disabled="true"
                            :label="$t('days-per-year')"
                            :value="tempVacationAccount.days_per_year"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field :disabled="!remainingVacationsEditable"
                            :label="$t('avail-this-year')"
                            :value="tempVacationAccount.remaining_days_this_year"
                            @change="actionShowChangeDialog"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field :disabled="true"
                            :label="$t('avail-last-year')"
                            :value="tempVacationAccount.remaining_days_last_year"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>

        <!-- Change Dialoge -->
        <v-dialog v-model="showChangeDialog">
          <v-card>
            <v-card-title>
              <v-spacer/>
              {{ $t('vacation-account-edit.change-dialog-title') }}
              <v-spacer/>
            </v-card-title>
            <v-card-text class="text-center">
              {{ $t('vacation-account-edit.change-dialog-text') }}
              {{ $t('vacation-account-edit.change-remaining-this-year') }}
              {{ $t('old-value') }}: {{ oldRemainingThisYear }}
              {{ $t('new-value') }}: {{ newRemainingThisYear }}
              <v-text-field v-model="vacationChangeNote"
                            :label="vacationChangeNoteLabel"
                            :error="vacationChangeNoteErrorState"
                            :error-messages="vacationChangeNoteErrorMessages"
                            @focus="clearTextField"
                            outlined>

              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="changeRemainingThisYear">{{ $t('yes') }}</v-btn>
              <v-btn @click="showChangeDialog=false">{{ $t('no') }}</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import api from "@/ApiFactory"

export default {
  props: {
    vacationaccount: {
      type: Object
    }
  },
  data() {
    return {
      showChangeDialog: false,
      oldRemainingThisYear: null,
      newRemainingThisYear: null,
      tempVacationAccount: this.vacationaccount,
      vacationChangeNoteLabel: this.$tc('Reason', 1) + ' *',
      vacationChangeNote: '',
      vacationChangeNoteErrorState: false,
      vacationChangeNoteErrorMessages: [],
      remainingVacationsEditable: false
    }
  },
  methods: {
    actionShowChangeDialog(value) {
      this.oldRemainingThisYear = this.tempVacationAccount.remaining_days_this_year
      this.newRemainingThisYear = value
      if (this.oldRemainingThisYear != this.newRemainingThisYear) {
        this.showChangeDialog = true
      }
    },
    changeRemainingThisYear() {
      if (this.vacationChangeNote) {
        const changeValue = this.newRemainingThisYear - this.oldRemainingThisYear
        const data = {
          action: (changeValue < 0) ? 'decrease' : 'increase',
          days: Math.abs(changeValue),
          reason: this.vacationChangeNote
        }
        api.vacationaccount.changeDayNumber(this.tempVacationAccount.id,data).then(response => {
          api.vacationaccount.retrieve(this.tempVacationAccount.id).then(response => {
            this.tempVacationAccount = response.data
            this.showChangeDialog = false
            this.$root.vtoast.show({message: 'Successfully updated.'})
          })
        })
        this.vacationChangeNote = ''
      } else {
        this.vacationChangeNoteErrorState = true
        this.vacationChangeNoteErrorMessages.push(this.$t('error-messages.reason-missing'))
      }
    },
    clearTextField() {
      this.vacationChangeNoteErrorState = false
      this.vacationChangeNoteErrorMessages = []
    },
    showAlert(type, message) {
      this.alertType = type
      this.alertMessage = message
      this.alert = true
    },
    routeToUsersVacationView() {
      this.$router.push({name: "vacations", query: {user_id: this.vacationaccount.user}})
    }
  },
  watch: {
    alert(value) {
      if (value) {
        let myTimer = window.setTimeout(() => {
          this.alert = false;
        }, 2000);
      }
    }
  },
  mounted() {
    // check if current user is vacationaccount manager and subsquently is allowed to edit
    if (this.$store.state.currentUser.groups.includes('vacationaccount_manager')) {
      this.remainingVacationsEditable = true
    }
  }
}
</script>