<template>
  <v-card v-if="vacation">
    <v-card-title :style="statusColor">
      <v-spacer></v-spacer>
      {{ this.vacation.get_status_display }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-text-field readonly :label="$t('name')"
                      :value="vacation.get_full_name">
        </v-text-field>

        <!-- Start Date -- including Date Picker in edit Mode -->
        <v-menu
            v-if="editMode"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start"
                :label="$t('vacation-create-dialog.start')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start"
              @input="menu1 = false"
              :allowed-dates="disablePastDates"
              :locale="$i18n.locale"
          ></v-date-picker>
        </v-menu>
        <v-text-field v-else readonly :label="$t('from')" :value="$d(vacation.start, 'short')">
          <!--          {{ $t('from') }} {{ $d(vacation.start, 'short') }}-->
        </v-text-field>
        <!-- -->

        <!-- Start Date -- including Date Picker in edit Mode -->
        <v-menu
            v-if="editMode"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end"
                :label="$t('vacation-create-dialog.end')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end"
              @input="menu2 = false"
              :allowed-dates="disableDatesBeforeStartDate"
              :locale="$i18n.locale"
          ></v-date-picker>
        </v-menu>
        <v-text-field v-else readonly :label="$t('until')" :value="$d(vacation.end, 'short')">
          <!--          {{  $t('until') }} {{ $d(vacation.end, 'short') }}-->
        </v-text-field>
        <!-- -->

        <!-- Type - including edit mode -->
        <v-select v-if="editMode"
                  v-model="type"
                  :items="typeOptions"
                  item-value="value"
                  item-text="name"
                  label="Type"
        ></v-select>
        <v-text-field v-else readonly :label="$t('type')" :value="vacation.get_type_display"></v-text-field>
        <v-text-field readonly :label="$t('vacation-days')" :value="vacationDays"></v-text-field>
        <v-text-field readonly :label="$t('status')" :value="vacation.get_status_display"></v-text-field>
        <v-text-field v-if="vacation.note" readonly :label="$t('note')" :value="vacation.note"></v-text-field>
        <v-text-field v-if="vacation.reviewed_at" readonly :label="$t('reviewed at')"
                      :value="$d(vacation.reviewed_at, 'short')"></v-text-field>
        <v-text-field v-if="vacation.reviewed_by" readonly :label="$t('reviewed by')"
                      :value="vacation.reviewed_by"></v-text-field>
        <v-text-field v-if="vacation.review_note" readonly :label="$t('review note')"
                      :value="vacation.review_note"></v-text-field>
        <v-textarea
            v-if="this.$store.state.groups.includes('vacation_manager')
            && vacation.status==STATUS_PENDING && !editMode"
            v-model="reviewNoteText"
            :label="$t('review note')"
        >
        </v-textarea>

      </v-list>
    </v-card-text>

    <!-- Actions selected by permissions -->
    <div v-if="this.$store.state.groups.includes('vacation_manager')">
      <div v-if="!editMode">
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="vacation.status==STATUS_PENDING">
            <v-btn @click="approveVacation" class="success ma-1">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn @click="denyVacation" class="error ma-1">
              <v-icon>mdi-hand-back-left</v-icon>
            </v-btn>
            <v-btn class="warning ma-1" @click="editMode=!editMode">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="update" class="ma-1">
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn @click="editMode=!editMode" class="ma-1">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </div>
    <v-card-actions v-else>
      <v-spacer></v-spacer>
      <v-btn class="warning" v-if="vacation.status==STATUS_PENDING" @click="editMode=!editMode">
        <v-icon>mdi-pencil</v-icon>
        edit
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import {Vacation} from "@/utils/vacation";
import api from '@/ApiFactory'

export default {
  name: 'VacationDetailsComponent',
  props: {
    id: {},
    isDialog: {type: Boolean, default: false}
  },
  data() {
    return {
      vacation: null,
      STATUS_PENDING: Vacation.STATUS_PENDING,
      STATUS_APPROVED: Vacation.STATUS_APPROVED,
      STATUS_REJECTED: Vacation.STATUS_REJECTED,
      reviewNoteText: '',
      start: null,
      end: null,
      type: null,
      editMode: false,
      typeOptions: [{name: this.$t('vacation-type.paid-vacation'), value: Vacation.TYPE_PAID},
        {name: this.$t('vacation-type.unpaid-vacation'), value: Vacation.TYPE_UNPAID}],
      dialog: false,
      vacationDays: null
    }
  },
  methods: {
    denyVacation() {
      this.$store.commit('activateLoading')
      const data = (this.reviewNoteText) ? {action: 'reject', review_note: this.reviewNoteText} : {action: 'reject', review_note: 'Vacation Rejected!'}
      api.vacations.review(this.vacation.id, data).then(response => {
        this.$emit('changed', this.vacation.id)
        this.$store.commit('deactivateLoading')
      })
    },
    approveVacation() {
      this.$store.commit('activateLoading')
      const data = {action: 'approve', review_note: this.reviewNoteText}
      api.vacations.review(this.vacation.id, data).then(response => {
        this.$emit('changed', this.vacation.id)
        this.$store.commit('deactivateLoading')
      })
    },
    update() {
      const data = {
        start: this.start + "T00:00",
        end: this.end + "T00:00",
        typ: this.type,
        user: this.vacation.user,
      }
      api.vacations.update(this.id, data).then(response => {
        this.vacation = Vacation.get_event_obj(response.data)
        this.$emit('changed', this.vacation.id)
      })
    },
    disablePastDates(val) {
      return val >= new Date().toISOString()
    },
    disableDatesBeforeStartDate(val) {
      if (this.disablePastDates(val)) {
        return val >= this.start
      } else {
        return false
      }
    },
    resetProps() {
      this.start = this.vacation.start.toISOString().substring(0, 10)
      this.end = this.vacation.end.toISOString().substring(0, 10)
      this.type = this.vacation.type
      this.note = this.vacation.note
    },
    calcVacationDays() {
      const data = {
        user_id: this.vacation.user,
        start_date: this.vacation.start.toISOString().substring(0, 10),
        end_date: this.vacation.end.toISOString().substring(0, 10)
      }
      api.reportingutils.calculate_workdays(data).then(response => {
        this.vacationDays = response.data.days
      })
    },
    loadVacation() {
      this.$store.commit('activateLoading')
      api.vacations.retrieve(this.id).then(vacation => {
        this.vacation = Vacation.get_event_obj(vacation.data)
        this.resetProps()
        this.calcVacationDays()
        this.$store.commit('deactivateLoading')
      })
    }
  },
  computed: {
    statusColor() {
      if(this.vacation) return 'background-color: ' + this.vacation.color + ';'
      else return ''
    }
  },
  watch: {
    id() {
      this.loadVacation()
    }
  },
  mounted() {
    this.loadVacation()
  }
}
</script>