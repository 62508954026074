<template>
  <v-snackbar :color="color" :timeout="timer" v-model="showSnackbar" top>
    <v-icon left>{{ icon }}</v-icon>{{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return{
      showSnackbar: false,
      message: '',
      color: 'success',
      icon: 'mdi-check',
      timer: 2000
    }
  },
  methods:{
    show(data) {
      this.message = data.message || 'missing "message".'
      this.color = data.color || 'success'
      this.timer = data.timer || 2000
      this.icon = data.icon || 'mdi-check'
      this.showSnackbar = true
    }
  }
}
</script>
