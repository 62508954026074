<template>
  <base-data-table-component :headers="headers"
                             :title="title"
                             :show-title="true"
                             :refreshListFunc="refreshListFunc"
                             :objectForwardFunc="objectForwardFunc"
                             :search="filterObj"
  >

    <template v-slot:top>
      <v-row>
        <v-col cols="12" md="2" lg="2" xl="2">
          <DatePickerComponent
              v-model="fromDateValue"
              :label="$t('from')"
          ></DatePickerComponent>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <DatePickerComponent
              v-model="toDateValue"
              :label="$t('until')"
          ></DatePickerComponent>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2" v-if="!user_id">
          <FilterComponent
              v-model="userValue"
              :items="userFilterItems"
              :label="$tc('User')"
          ></FilterComponent>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <FilterComponent
              v-model="statusValues"
              :items="statusFilterItems"
              :label="$t('status')"
              :multiple="true"
          ></FilterComponent>
        </v-col>
      </v-row>
    </template>

    <template #action v-if="showActions">
      <base-button-component :tooltip="true">
        <v-icon>mdi-plus</v-icon>
        <template #tooltip>New Vacation</template>
      </base-button-component>
    </template>

    <template #item.get_status_display="{item}">
      <v-chip label
              small
              :color="Vacation.COLOR_PENDING"
              v-if="item.status===Vacation.STATUS_PENDING"
      >
        {{ item.get_status_display }}
      </v-chip>
      <v-chip label
              small
              :color="Vacation.COLOR_APPROVED"
              v-if="item.status===Vacation.STATUS_APPROVED"
      >
        {{ item.get_status_display }}
      </v-chip>
      <v-chip label
              small
              :color="Vacation.COLOR_REJECTED"
              v-if="item.status===Vacation.STATUS_REJECTED"
      >
        {{ item.get_status_display }}
      </v-chip>
    </template>

  </base-data-table-component>
</template>

<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import BaseDataTableComponent from "@/components/templates/BaseDataTableComponent";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent";
import FilterComponent from "@/components/templates/FilterComponent.vue";
import DatePickerComponent from "@/components/templates/DatePickerComponent.vue";
import api from "@/ApiFactory"
import {Vacation} from "@/utils/vacation";
import user from "@/api/user";

export default {
  name: "VacationListComponent",
  components: {BaseButtonComponent, BaseDataTableComponent, BaseCardComponent, FilterComponent, DatePickerComponent},
  props: {
    user_id: {
      default: null,
      required: false
    },
    title: {
      type: String,
      default: 'Vacation',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    },
    showActions: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      statusValues: [Vacation.STATUS_APPROVED, Vacation.STATUS_PENDING],
      fromDateValue: '',
      toDateValue: '',
      userValue: null,
      userFilterItems: [],
    }
  },
  computed: {
    Vacation() {
      return Vacation
    },
    // List of headers - this is mandatory for BaseDataTableComponent in order to extract items
    headers() {
      return [
        {text: this.$t('name'), value: 'get_full_name'},
        {text: this.$t('type'), value: 'get_type_display'},
        {text: this.$t('start'), value: 'start'},
        {text: this.$t('end'), value: 'end'},
        {text: this.$t('status'), value: 'get_status_display'},
      ]
    },
    filterObj() {
      let filter = {}
      if (this.fromDateValue) filter.from_date = this.fromDateValue
      if (this.toDateValue) filter.to_date = this.toDateValue
      filter.rejected = this.statusValues.includes(Vacation.STATUS_REJECTED)
      filter.approved =  this.statusValues.includes(Vacation.STATUS_APPROVED)
      filter.pending = this.statusValues.includes(Vacation.STATUS_PENDING)
      if (this.userValue) filter.user_id = this.userValue
      filter.json = true
      return filter
    },
    statusFilterItems() {
      return [
        {text: this.$t('approved'), value: Vacation.STATUS_APPROVED},
        {text: this.$t('pending'), value: Vacation.STATUS_PENDING},
        {text: this.$t('rejected'), value: Vacation.STATUS_REJECTED},
      ]
    },
    filterDisabled() {
      if (this.toDateValue && this.fromDateValue) return false
      else return true
    }
  },
  methods: {
    user() {
      return user
    },
    // Utility function to set correct local date format
    cleanedData(jsonList) {
      jsonList.results = jsonList.results.map(item => {
        item.start = this.$d(new Date(item.start.substring(0, 10)), 'short')
        item.end = this.$d(new Date(item.end.substring(0, 10)), 'short')
        return item
      })
      return jsonList
    },
    // Mandatory async function for BaseDataTableComponent return json object formatted in paginated DRF style
    async refreshListFunc(page) {
      let params = {}
      if (page) params.page = page
      for (const [key, value] of Object.entries(this.filterObj)) {
        params[key] = value
      }
      console.log(params)
      const data = await api.vacations.list(params)
      return this.cleanedData(data.data)
    },
    // Optional function for BaseDataTableComponent in order to route to corresponding detail view
    objectForwardFunc(item) {
      this.$router.push({name: 'vacation-details', params: {id: item.id.toString()}})
    },
  },
  mounted() {
    api.user.list().then(response => {
      this.userFilterItems = response.data.results.map(item => {
        return (item.person) ? {text: item.person.first_name + ' ' + item.person.last_name, value: item.id} : null
      })
    })
  },
  beforeMount() {
    if (this.user_id) this.userValue = this.user_id
  }
}
</script>
