<template>
  <v-card flat outlined>
    <!-- alert -->
    <v-alert
        v-model="showAlert"
        type="error"
        transition="scale-transition"
    >
      {{ errorMessage }}
    </v-alert>

    <!-- Title column right -->
    <v-col class="text-right">

      <!-- Filter button with filter menu-->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" v-bind="attrs"
                 v-on="on">
            <v-icon>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-list class="ma-0">
          <v-list-item>
            <v-checkbox v-model="filterRejected" :label="$tc('rejected',2)" @change="refreshEvents"></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox v-model="filterPending" :label="$tc('pending',2)" @change="refreshEvents"></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox v-model="filterApproved" :label="$tc('approved',2)" @change="refreshEvents"></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- List view button -->
      <base-button-component :tooltip="true" @click="enabledListView=!enabledListView">
        <template #default>
          <v-icon v-if="!enabledListView">
            mdi-format-list-bulleted
          </v-icon>
          <v-icon v-else>
            mdi-calendar-month
          </v-icon>
        </template>
        <template #tooltip>
          <span v-if="!enabledListView">{{ $t('list view') }}</span>
          <span v-else>{{ $t('calendar view') }}</span>
        </template>
      </base-button-component>

    </v-col>
    <!-- Calender Component -->
    <div v-if="!enabledListView">
      <calendar-component
          ref="calendar"
          :events="events"
          :start="calendarStart"
          :end="calendarEnd"
          :holidays="holidays"
          @clickedEvent="showVacationDialog"
          @createEvent="displayCreateDialog"
          @calendarChanged="calendarChanged"
      ></calendar-component>
    </div>

    <!-- List View of current month -->
    <div v-if="enabledListView">
      <v-row>
        <v-spacer></v-spacer>
        <span class="v-toolbar__title ma-6"> {{ calendarMonthString }} {{ calendarYearString }}</span>
        <v-spacer></v-spacer>
      </v-row>
      <v-card class="ma-6" v-for="vacation in events" :key="events.id" elevation="4"
              :to="{name: 'vacation-details', params: {id: vacation.id}}">
        <v-card-actions v-if="vacation.status==STATUS_PENDING" :style="{background: COLOR_PENDING}"/>
        <v-card-actions v-else-if="vacation.status==STATUS_APPROVED" :style="{background: COLOR_APPROVED}"/>
        <v-card-actions v-else-if="vacation.status==STATUS_REJECTED" :style="{background: COLOR_REJECTED}"/>
        <v-card-text>
          <v-list-item three-line>
            <v-list-item-content>
              {{ vacation.get_full_name }}
              <v-spacer></v-spacer>
              {{ vacation.get_type_display }}
              <v-spacer></v-spacer>
              {{ $d(vacation.start, 'short') }} - {{ $d(vacation.end, 'short') }}
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </div>

    <!-- Edit Dialog -->
    <v-dialog v-model="showEventDialog"
              max-width="350">
      <vacation-details-component v-if="focusedEvent"
                                  :id="focusedEvent.id"
                                  :is-dialog="true"
                                  @changed="refreshEvents"
      ></vacation-details-component>
    </v-dialog>

    <!-- Create Vacation Dialog -->
    <div v-if="guard">
      <create-vacation-dialog
          :show="showCreateDialog"
          :startDate="startDate"
          :userId="guard.user"
          @closed="closeCreateDialog"
          @create="postVacation"
      ></create-vacation-dialog>
    </div>
    <div v-else>
      <create-vacation-dialog
          :show="showCreateDialog"
          :startDate="startDate"
          @closed="closeCreateDialog"
          @create="postVacation"
      ></create-vacation-dialog>
    </div>

  </v-card>
</template>

<script>
import CalendarComponent from "@/components/CalendarComponent";
import CreateVacationDialog from "@/components/CreateVacationDialog";
import EditVacationDialog from "@/components/EditVacationDialog";
import {Vacation} from "@/utils/vacation";
import api from '@/ApiFactory'
import VacationDetailsComponent from "@/components/VacationDetailsComponent";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent";

export default {
  components: {
    BaseButtonComponent,
    VacationDetailsComponent, EditVacationDialog, CreateVacationDialog, CalendarComponent
  },
  props: {
    guard: {
      default: null,
    }
  },
  data() {
    return {
      events: [],
      showEventDialog: false,
      showCreateDialog: false,
      showEditDialog: false,
      showFilterMenu: false,
      showAlert: false,
      filterRejected: false,
      filterPending: true,
      filterApproved: true,
      errorMessage: '',
      focusedEvent: null,
      STATUS_PENDING: Vacation.STATUS_PENDING,
      STATUS_APPROVED: Vacation.STATUS_APPROVED,
      STATUS_REJECTED: Vacation.STATUS_REJECTED,
      COLOR_PENDING: Vacation.COLOR_PENDING,
      COLOR_APPROVED: Vacation.COLOR_APPROVED,
      COLOR_REJECTED: Vacation.COLOR_REJECTED,
      startDate: '',
      calendarStart: null,
      calendarEnd: null,
      calendarMonthString: '',
      calendarYearString: '',
      currentYear: null,
      reviewNoteText: '',
      enabledListView: false,
      holidays: null,
    }
  },
  methods: {
    showVacationDialog(event, timed) {
      this.focusedEvent = event
      this.showEventDialog = true
    },
    displayCreateDialog(dateTime, mouseEvent) {
      this.startDate = dateTime.date
      this.showCreateDialog = true
    },
    postVacation(vacation_obj) {
      this.$store.commit('activateLoading')
      // Modifie dates to accepeted format
      vacation_obj.start = vacation_obj.start + 'T00:00'
      vacation_obj.end = vacation_obj.end + 'T00:00'
      api.vacations.create(vacation_obj).then(new_vacation => {
        this.refreshEvents()
        this.$store.commit('deactivateLoading')
      })
      // postVacation(this.$api_url, this.$store.state.token, vacation_obj).then(data => {
      //   this.refreshEvents()
      //   this.$store.commit('deactivateLoading')
      // }).catch(error => {
      //   this.errorMessage = error
      //   this.showAlert = true
      //   this.$store.commit('deactivateLoading')
      // })
    },
    closeCreateDialog() {
      this.showCreateDialog = false
    },
    closeEditDialog() {
      this.showEditDialog = false
      this.showEventDialog = false
    },
    // updateVacation(vacation_obj) {
    //   this.$store.commit('activateLoading')
    //   updateVacation(this.$api_url, this.$store.state.token, vacation_obj).then(
    //       vacation => {
    //         this.refreshEvents()
    //         this.$store.commit('deactivateLoading')
    //       }
    //   ).catch(error => {
    //     this.errorMessage = error.message
    //     this.showAlert = true
    //     this.$store.commit('deactivateLoading')
    //   })
    // },
    calendarChanged(start, end) {
      this.calendarStart = start.date
      this.calendarEnd = end.date
      this.calendarMonthString = new Date(start.date).toLocaleString(this.$i18n.locale, {month: 'long'})
      this.calendarYearString = new Date(start.date).toLocaleString(this.$i18n.locale, {year: 'numeric'})
      this.refreshEvents()


      // Fetch holidays in case there is a guard object available
      if (this.guard) {
        let hasYearChanged = false
        if (this.currentYear) {
          if (start.year !== this.currentYear) {
            hasYearChanged = true
            this.currentYear = start.year
          }
        } else {
          this.currentYear = start.year
          hasYearChanged = true
        }
        // If year has changed fetch new holidays list based on changed year
        if (hasYearChanged) {
          let options = {year: start.year}
          api.guards.memberships(this.guard.id).then(membership => {
                const main_project = membership.data.results.find(element => element.is_main == true).project
                // Check if main project exists else fetch standard holidays
                if (main_project) {
                  // Retrieve associated country object
                  const country = main_project.country.iso2_code
                  const county = main_project.county.county_code.split('-')[1]
                  if (country) {
                    options.country = country
                  }
                  if (county) {
                    options.county = county
                  }
                  api.lookupholidays.list(options).then(holidays => {
                    // Nasty check if a country is supported or not. If it returns a list take this as list of holidays
                    // else refetch without country / county options.
                    if (Array.isArray(holidays.data)) this.holidays = holidays.data
                    else {
                      delete options.country
                      delete options.county
                      api.lookupholidays.list(options).then(holidays => {
                        this.holidays = holidays.data
                      })
                    }
                  })
                }
              }
          )
        }
      }
    },
    refreshEvents() {
      this.$store.commit('activateLoading')
      let params = {
        from_date: this.calendarStart,
        to_date: this.calendarEnd,
        rejected: this.filterRejected,
        pending: this.filterPending,
        approved: this.filterApproved,
      }
      if (this.guard) params.user_id = this.guard.user
      api.vacations.list(params).then(vacations => {
        this.events = vacations.data.results.map(vacation => {
          return Vacation.get_event_obj(vacation)
        })
        this.showEventDialog = false
        this.$store.commit('deactivateLoading')
      })
    },
// updateVacation(vacation_obj) {
//   this.$store.commit('activateLoading')
//   updateVacation(this.$api_url, this.$store.state.token, vacation_obj).then(
//       vacation => {
//         this.refreshEvents()
//         this.$store.commit('deactivateLoading')
//       }
//   ).catch(error => {
//     this.errorMessage = error.message
//     this.showAlert = true
//     this.$store.commit('deactivateLoading')
//   })
// },
  },
  computed: {
    statusColor() {
      return 'background-color: ' + this.focusedEvent.color + ';'
    }
  }
  ,
  created() {
    this.calendarStart = new Date().toISOString().substring(0, 10)
    this.calendarEnd = new Date().toISOString().substring(0, 10)
  }
}
</script>

