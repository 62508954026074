<template>
  <base-data-table-component :title="$tc('Project',2)"
                             :show-title="true"
                             :headers="headers"
                             :refresh-list-func="refreshListFunc"
                             :object-forward-func="forwardToDetailView"
  >
    <!-- Custom is_active item slot -->
    <template v-slot:item.is_active="{item}">
      <v-icon v-if="item.is_active" color="success">mdi-check-circle</v-icon>
      <v-icon v-else>mdi-minus-circle</v-icon>
    </template>
  </base-data-table-component>
</template>

<script>
import BaseDataTableComponent from "@/components/templates/BaseDataTableComponent";
import api from "@/ApiFactory";

export default {
  components: {BaseDataTableComponent},
  data () {
    return {
    }
  },
  mounted () {
    document.title = 'Guardport Projects'
  },
  computed: {
    headers() {
      return [
        {text: this.$t('Shortname'), value: 'shortname'},
        {text: this.$t('Client'), value: 'client_shortname'},
        {text: this.$t('Country'), value: 'country.iso2_code'},
        {text: this.$t('County'), value: 'county.county_name_en'},
        {text: this.$t('Active'), value: 'is_active'},
      ]}
  },
  methods: {
    async refreshListFunc(page) {
      this.$store.commit('activateLoading')
      let params = {'page': page}
      return await api.projects.list(params).then(
          response => {
            this.$store.commit('deactivateLoading')
            return response.data
          }
      )
    },
    forwardToDetailView(project) {
      this.$router.push({name: 'project-details', params: {id: project.id.toString()}})
    },
  },
}
</script>
