// NPM
import Vue from 'vue';
import Vuex from 'vuex';
// PROJECT
import api from "@/ApiFactory";
import TokenService from "@/utils/token_service";
import {LOGIN, LOGOUT, CHECK_AUTH, UPDATE_USER} from "./action-types";
import {SET_AUTH, PURGE_AUTH, SET_ERROR, SET_USER} from "./mutation-types";

import alerts from "./modules/alerts";
import logbook from "./modules/logbook";
import i18n from "@/i18n";

Vue.use(Vuex)

export default new Vuex.Store({
  // Enable strict mode in development to get a warning
  // when mutating state outside a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  
  modules: {
    alerts,
    logbook,
  },
  
  state: {
    currentUser: {},
    preferences: {},
    groups: [],
    person: [],
    permissions: null,
    initials: '?',
    
    loading: false,
    
    alerts_enabled: false,

    errors: null,
    isAuthenticated: !!TokenService.getToken(),
  },
  
  getters: {
  },
  
  mutations: {
    activateLoading (state) {
      state.loading = true
    },
    deactivateLoading (state) {
      state.loading = false
    },
    setCurrentUser(state, userObj) {
      state.currentUser = userObj
      this.commit('setPreferences', userObj.preferences)
      this.commit('setGroups', userObj.groups)
      this.commit('setPerson', userObj.person)
      this.commit('setInitials', userObj.person)
    },
    clearUser(state) {
      state.currentUser = {}
    },
    setPreferences(state, prefObj) {
      state.preferences = prefObj
      i18n.locale = state.preferences.ui_lang
    },
    setGroups(state, groupArray) {
      state.groups = groupArray
    },
    setPerson(state, personArray) {
      state.person = personArray
    },
    setInitials(state, personObj) {
      let c1 = personObj.first_name.substring(0, 1).toUpperCase()
      let c2 = personObj.last_name.substring(0, 1).toUpperCase()
      state.initials = c1 + c2
    },
    setPermissions(state, permissions_obj) {
      state.permissions = permissions_obj
    },
    clearPermissions(state) {
      state.permissions = null
    },
    enableAlerts(state) {
      state.alerts_enabled = true
    },
    disableAlerts(state) {
      state.alerts_enabled = false
    },

    [SET_ERROR](state, error) {
      state.errors = error
    },
    [SET_AUTH](state, token) {
      console.log("SET AUTH")
      state.isAuthenticated = true
      state.errors = {}
      TokenService.saveToken(token)
    },
    [PURGE_AUTH](state) {
      console.log("PURGING")
      state.isAuthenticated = false
      state.currentUser = {}
      state.preferences = {}
      state.groups = []
      state.errors = null
      state.alerts.new_alerts_count = 0
      TokenService.purgeToken()
    },
    // [SET_USER](state, user) {
    //     state.user = user
    //     console.log("SET_USER", user)
    //     // console.log(user.groups.includes('logbook_manager'))
    //     console.log(user.groups)
    // }
    
  },
  
  actions: {
    [LOGIN](context, credentials) {
      api.auth.login(credentials)
        .then(
          response => {
            console.log("LOGIN", response.data)
            context.commit(SET_AUTH, response.data.token)
            context.dispatch(CHECK_AUTH).then(r => console.log("DISPATCHED CHECK_AUTH to get User"))
          }
        ).catch(response => {
        console.log("LOGIN -> error: ", response)
        context.commit(SET_ERROR, response.data)
      })
    },
    [LOGOUT](context) {
      context.commit("clearUser")
      context.commit(PURGE_AUTH)
    },
    [CHECK_AUTH](context) {
      if (TokenService.getToken()) {
        if (this.state.isAuthenticated) {
          api.auth.getCurrentUser()
            .then(response => {
              // context.commit(SET_USER, response.data)
              // TODO: remove deprecated global vuex stuff
              context.commit('setCurrentUser', response.data)
            })
            .catch(({ response }) => {
              context.commit(SET_ERROR, response.data.errors)
            })
        }
      } else {
        context.commit(PURGE_AUTH)
      }
    },
  }
})
