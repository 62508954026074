<template>
  <base-data-table-component :title="title"
                             :show-title="true"
                             :headers="headers"
                             :refresh-list-func="refreshListFunc"
                             :object-forward-func="forwardToDetailView"
                             :search="filterObj"
  >

    <template v-slot:top>
      <!-- Search -->
      <v-row>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
              v-model="searchValue"
              :label="$t('search')"
              class="mx-4"
              append-icon="mdi-magnify"
              single-line
          ></v-text-field>
        </v-col>

        <!-- Filtering Projects -->
        <v-col cols="12" md="2" lg="2" xl="2">
          <FilterComponent
              v-model="clientValue"
              :items="clientFilterItems"
              :label="$tc('Client')"
              :disabled="!Object.is(projectValue, null)"
          ></FilterComponent>
        </v-col>


        <!-- Filtering Projects -->
        <v-col cols="12" md="2" lg="2" xl="2">
          <FilterComponent
              v-model="projectValue"
              :items="projectFilterItems"
              :label="$tc('Project',1)"
              :disabled="!Object.is(clientValue, null)"
          ></FilterComponent>
        </v-col>

        <!-- Filtering Guards -->
        <v-col cols="12" md="2" lg="2" xl="2" v-if="$store.state.groups.includes('accounts_manager')">
          <FilterComponent
              v-model="userValue"
              :items="userFilterItems"
              :label="$tc('User',1) "
          ></FilterComponent>
        </v-col>

        <!-- Filtering Categories -->
        <v-col cols="12" md="2" lg="2" xl="2">
          <FilterComponent
              v-model="categoryValues"
              :items="categoryFilterItems"
              :label="$tc('Category',1)"
              :multiple="false"
          ></FilterComponent>
        </v-col>

        <!-- Filtering Categories -->
        <v-col cols="12" md="2" lg="2" xl="2">
          <FilterComponent
              v-model="timeRangeValue"
              :items="timeRangeFilterItems"
              :label="$t('Time Range')"
              :multiple="false"
          ></FilterComponent>
        </v-col>
      </v-row>
    </template>

    <!-- Replace project it by project shortname -->
    <template v-slot:item.project="{item}">{{ item.project_shortname }}</template>

    <!-- Customize appearance pf category -->
    <template v-slot:item.category="{item}">
      <v-chip label small v-if="item.category===-1" color="gray">{{ item.get_category_display }}</v-chip>
      <v-chip label small v-if="item.category===0" color="info">{{ item.get_category_display }}</v-chip>
      <v-chip label small v-if="item.category===1" color="warning">{{ item.get_category_display }}</v-chip>
      <v-chip label small v-if="item.category===2" color="error">{{ item.get_category_display }}</v-chip>
      <v-chip label small v-if="item.category===3" color="info">{{ item.get_category_display }}</v-chip>
    </template>

    <!-- Customize Entry Type -->
    <template #item.entry_type="{item}">
      <v-chip label small v-if="item.entry_type===Logbook.ENTRY_TYPE_STICKY" color="gray">
        {{ item.get_entry_type_display }}
      </v-chip>
      <v-chip label small v-else-if="item.entry_type===Logbook.ENTRY_TYPE_PHONE_CALL_OUTBOUND" color="gray">
        <v-icon small left>mdi-phone-outgoing</v-icon>
        {{ $tc('Out') }}
      </v-chip>
      <v-chip label small v-else-if="item.entry_type===Logbook.ENTRY_TYPE_PHONE_CALL_INBOUND" color="gray">
        <v-icon small left>mdi-phone-incoming</v-icon>
        {{ $tc('In') }}
      </v-chip>
      <v-chip label small v-else-if="item.camera_name" color="gray">
        <v-icon small left>mdi-cctv</v-icon>
        {{ item.camera_name }}
      </v-chip>
    </template>

    <!-- Customize Datetime fields -->
    <template v-slot:item.created_at="{item}">{{ getISO(item.created_at, item.project_timezone) }}</template>
    <template v-slot:item.created_at_utc="{item}">{{ getUTC(item.created_at) }}</template>

  </base-data-table-component>
</template>

<script>
import api from "@/ApiFactory";
import BaseDataTableComponent from "@/components/templates/BaseDataTableComponent.vue";
import {DateTime} from "luxon";
import FilterComponent from "@/components/templates/FilterComponent.vue";
import {Logbook} from "@/utils/logbook";

export default {
  components: {BaseDataTableComponent, FilterComponent},
  data() {
    return {
      title: 'Logbook',
      searchValue: '',
      categoryValues: null,
      projectValue: null,
      projectFilterItems: [],
      userValue: null,
      userFilterItems: [],
      timeRangeValue: null,
      clientValue: null,
      clientFilterItems: []
    }
  },
  computed: {
    Logbook() {
      return Logbook
    },
    headers() {
      return [
        {text: this.$tc('Project'), value: 'project'},
        {text: this.$tc('Category'), value: 'category'},
        {text: this.$t('Type/Cam'), value: 'entry_type'},
        {text: this.$tc('Logtext'), value: 'logtext'},
        {text: this.$tc('Created_by', 1), value: 'created_by_fullname'},
        {text: this.$tc('Created_at', 1), value: 'created_at'},
        {text: 'Created at UTC', value: 'created_at_utc'},
      ]
    },
    filterObj() {
      let filter = {}
      if (this.searchValue) filter.search = this.searchValue
      if (this.clientValue) filter.client_id = this.clientValue
      if (this.projectValue) filter.project_id = this.projectValue
      if (this.userValue) filter.user_id = this.userValue
      if (this.categoryValues != null) filter.category_id = this.categoryValues
      if (this.timeRangeValue) filter.daterange = this.timeRangeValue
      return filter
    },
    categoryFilterItems() {
      return [
        {text: 'Info', name: 'info', value: 0},
        {text: this.$t('Notice') + ' / ' + this.$t('Warning'), name: 'warning', value: 1},
        {text: this.$t('Danger') + ' / ' + this.$t('Risk'), name: 'danger', value: 2},
        {text: this.$t('Shift'), name: 'shift', value: 3},
      ]
    },
    timeRangeFilterItems() {
      return [
        {text: this.$t('Today'), value: 'today'},
        {text: this.$t('Yesterday'), value: 'yesterday'},
        {text: this.$t('This Month'), value: 'thismonth'},
        {text: this.$t('Last Month'), value: 'lastmonth'},
        {text: this.$t('This Year'), value: 'thisyear'},
      ]
    }
  },
  methods: {
    async refreshListFunc(page) {
      this.$store.commit('activateLoading')
      let params = {}
      if (page) params.page = page
      for (const [key, value] of Object.entries(this.filterObj)) {
        params[key] = value
      }
      return await api.logbook.list(params).then(
          response => {
            this.$store.commit('deactivateLoading')
            return response.data
          }
      )
    },
    forwardToDetailView(logbook) {
      this.$router.push({name: 'logbook-details', params: {id: logbook.id.toString()}})
    },
    getISO(dt, tz) {
      // grab the timezone from the projects location
      return DateTime.fromISO(dt, {zone: tz}).toFormat('yyyy-MM-dd TTT')
    },
    getUTC(dt) {
      return DateTime.fromISO(dt).toUTC().toFormat('yyyy-MM-dd TTT')
    }
  },
  mounted() {
    document.title = 'Guardport Logbook'
    api.projects.list().then(response => {
      this.projectFilterItems = response.data.results.map(item => {
        return {text: item.shortname, value: item.id}
      })
    })
    api.user.list().then(response => {
      this.userFilterItems = response.data.results.map(item => {
        return (item.person) ? {text: item.person.first_name + ' ' + item.person.last_name, value: item.id} : null
      })
    }),
    api.clients.list().then(response => {
      this.clientFilterItems = response.data.results.map(item => {
        return {text: item.shortname, value: item.id}
      })
    })
  }
}
</script>
