<template>
  <v-card flat outlined
          :disabled="disabled"
  >
    <v-card-title v-if="showTitle">
      <slot name="title">{{ title }}</slot>
      <v-spacer></v-spacer>
      <slot name="action"></slot>
    </v-card-title>
    <v-divider v-if="showTitleDivider"></v-divider>
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BaseCardComponent',
  props: {
    title: {
      type: String
    },
    showTitle: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    showTitleDivider: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click']
}
</script>
