<template>
  <base-data-table-component :title="title"
                             :show-title="true"
                             :headers="headers"
                             :refresh-list-func="refreshListFunc"
                             :object-forward-func="forwardToDetailView"
                             :search="searchObj"
  >
    <template v-slot:top>
      <v-text-field
          v-model="searchValue"
          label="search"
          class="mx-4"
          append-icon="mdi-magnify"
          single-line
      ></v-text-field>
    </template>

    <!-- Custom Item slots -->
    <template v-slot:item.is_active="{item}">
      <v-icon v-if="item.is_active">mdi-check-bold</v-icon>
      <v-icon v-else>mdi-close-thick</v-icon>
    </template>
    <template v-slot:item.is_active_guard="{item}">
      <v-icon v-if="item.is_active_guard">mdi-check-bold</v-icon>
      <v-icon v-else>mdi-close-thick</v-icon>
    </template>
    <template v-slot:item.is_employee="{item}">
      <v-icon v-if="item.is_employee">mdi-check-bold</v-icon>
      <v-icon v-else>mdi-close-thick</v-icon>
    </template>
    <template v-slot:item.gender="{item}">
      <v-icon v-if="item.gender==='M'">mdi-human-male</v-icon>
      <v-icon v-else-if="item.gender==='F'">mdi-human-female</v-icon>
      <v-icon v-else>mdi-human-male-female</v-icon>
    </template>

  </base-data-table-component>
</template>

<script>
import api from "@/ApiFactory"
import BaseDataTableComponent from "@/components/templates/BaseDataTableComponent";

export default {
  name: 'Guards',
  components: {BaseDataTableComponent},
  data() {
    return {
      title: this.$tc('Guard', 2),
      searchValue: ''
    }
  },
  mounted () {
    document.title = 'Guardport Guards'
  },
  computed: {
    headers() {
      return [
        {text: this.$t('first name'), value: 'first_name'},
        {text: this.$t('last name'), value: 'last_name'},
        {text: this.$t('is_active'), value: 'is_active'},
        {text: this.$t('is_active_guard'), value: 'is_active_guard'},
        {text: this.$t('is_employee'), value: 'is_employee'},
        {text: this.$t('gender'), value: 'gender'},
      ]
    },
    searchObj() {
      return {q: this.searchValue}
    }
  },
  methods: {
    forwardToDetailView(guard) {
      this.$router.push({name: 'guard-details', params: {id: guard.id.toString()}})
    },
    async refreshListFunc(page) {
      let params = {}
      if (page) params.page = page
      for (const [key, value] of Object.entries(this.searchObj)) {
        if(value) params[key] = value
      }
      return await api.guards.list(params).then(
          response => {
            this.$store.commit('deactivateLoading')
            return response.data
          }
      )
    }
  },
}
</script>
