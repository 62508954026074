<template>
  <base-card-component :title="$tc('History', 1)" :show-title="true">
    <template #default>
      <template>
        <v-expansion-panels focusable>
          <v-expansion-panel v-for="(item, i) in history" :key="i">

            <v-expansion-panel-header>
              {{ item.history_type }} {{ formatRelative(item.history_date) }} by {{ item.history_user_fullname }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-list-item v-if="item.history_date" two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>History date</v-list-item-subtitle>
                  <v-list-item-title>{{ format2projectTime(item.history_date) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="item.logtext" two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Logtext</v-list-item-subtitle>
                  <v-list-item-title>{{  item.logtext }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.history_change_reason">
                <v-list-item-content>
                  <v-list-item-subtitle>Change reason</v-list-item-subtitle>
                  <v-list-item-title>{{ item.history_change_reason }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.time_of_occurence">
                <v-list-item-content>
                  <v-list-item-subtitle>Time of Occurrence</v-list-item-subtitle>
                  <v-list-item-title>{{ format2projectTime(item.time_of_occurence) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="item.created_at">
                <v-list-item-content>
                  <v-list-item-subtitle>Created at</v-list-item-subtitle>
                  <v-list-item-title>{{ format2projectTime(item.created_at) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.created_by">
                <v-list-item-content>
                  <v-list-item-subtitle>Created by</v-list-item-subtitle>
                  <v-list-item-title>{{ item.history_user_fullname }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
    </template>
  </base-card-component>
</template>

<script>
import {DateTime} from "luxon";
import BaseCardComponent from "@/components/templates/BaseCardComponent";


export default {
  components: {BaseCardComponent},
  props: {
    history: {
      history_user_fullname: '',
      history_change_reason: '',
      history_date: null,
      history_type: '',
    },
    logbook: {
      project: {
        tz: null,
      },
    },
  },
  data() {
    return {
      created_at: null,
      created_at_utc: null,
      occurrence: null,
      occurrence_utc: null,
      duration: 'not set',
    }
  },
  methods: {
    formatRelative(dt) {
      return DateTime.fromISO(dt).toRelative()
    },
    format2projectTime(dt) {
      return DateTime.fromISO(dt, {zone: this.logbook.project.tz}).toFormat('yyyy-MM-dd TTT')
    }
  },
  mounted() {
  }
}
</script>
