<template>
  <vacation-list-component
      :title="$tc('Vacation',2)"
      :show-title="true"
      :show-actions="true"
  ></vacation-list-component>
</template>

<script>
import VacationListComponent from "@/components/vacation/VacationListComponent.vue";

export default {
  components: {VacationListComponent}
}

</script>
