<template>
  <v-alert :value="showAlert" :type="type" transition="scale-transition" class="mt-5">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    message: {
      type: String
    },
    type: {
      type: String,
      default: 'success'
    }
  },
  emits: ['input:value'],
  data() {
    return {}
  },
  computed: {
    showAlert() {
      return this.value
    }
  },
  watch: {
    value(value) {
      if (value) {
        let myTimer = window.setTimeout(() => {
          this.$emit('input:value', false)
        }, 2000);
      }
    }
  },
}
</script>