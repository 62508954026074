// NPM
import Vue from 'vue'
import VueRouter from 'vue-router'
// PROJECT
import HomeView from '../views/HomeView.vue'
import AlertListView from "@/views/alerts/AlertListView";
import AlertDetailsView from "@/views/alerts/AlertDetailsView";
import VacationView from "@/views/availability/VacationView"
import ProjectListView from "@/views/projects/ProjectListView"
import LoginView from "@/views/LoginView"
import VacationDetailsView from "@/views/availability/VacationDetailsView";
import SettingsView from "@/views/SettingsView";
import GuardListView from "@/views/guards/GuardListView";
import GuardDetailsView from "@/views/guards/GuardDetailsView";
import ProjectDetailsView from "@/views/projects/ProjectDetailsView";
import LogbookListView from "@/views/logbook/LogbookListView";
import LogbookDetailsView from "@/views/logbook/LogbookDetailsView";
import VacationListView from "@/views/availability/VacationListView";
import NotFoundView from "@/views/NotFoundView";
import Store from "../../store";
import ChangelogView from "@/views/ChangelogView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            breadcrumb: [],
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {guest: true}
    },
    {
        path: '/guards',
        name: 'guards',
        component: GuardListView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Guards', to: {link: 'guards'}}
            ],
            requiresAuth: true
        }
    },
    {
        path: '/guards/:id/',
        name: 'guard-details',
        component: GuardDetailsView,
        props: true,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Guards', to: {name: 'guards'}},
                {text: 'Guard details', to: {name: ''}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/availability/vacation/',
        name: 'vacations',
        component: VacationView,
        meta: {requiresAuth: true}
    },
    {
        path: '/availability/vacation/list/',
        name: 'vacation-list',
        component: VacationListView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Vacations', to: {name: 'vacation-list'}},
            ],

            requiresAuth: true
        }
    },
    {
        path: '/availability/vacation/:id/',
        name: 'vacation-details',
        component: VacationDetailsView,
        props: true,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Vacations', to: {name: 'vacation-list'}},
                {text: 'Vacation details', to: {name: ''}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/projects/',
        name: 'projects',
        component: ProjectListView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Projects', to: {name: 'projects'}},
            ],
            requiresAuth: true
        },
    },
    {
        path: '/projects/:id/',
        name: 'project-details',
        component: ProjectDetailsView,
        props: true,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Projects', to: {name: 'projects'}},
                {text: 'Project details', to: {name: ''}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/settings/',
        name: 'settings',
        component: SettingsView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Settings', to: {name: 'settings'}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/changelog/',
        name: 'changelog',
        component: ChangelogView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'What\'s new', to: {name: 'changelog'}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/alerts/verkada-alert/',
        name: 'verkada-alerts',
        component: AlertListView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Alerts', to: {name: 'verkada-alerts'}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/alerts/verkada-alert/:id/',
        name: 'verkada-alert-details',
        component: AlertDetailsView,
        props: true,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Alerts', to: {name: 'verkada-alerts'}},
                {text: 'Alert details', to: {name: ''}}
            ],
            requiresAuth: true
        }
    },
    {
        path: '/logbook/logbookentry/',
        name: 'logbook',
        component: LogbookListView,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Logbook', to: {name: 'logbook'}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '/logbook/logbookentry/:id/',
        name: 'logbook-details',
        component: LogbookDetailsView,
        props: true,
        meta: {
            breadcrumb: [
                {text: 'Home', to: {name: 'home'}},
                {text: 'Logbook', to: {name: 'logbook'}},
                {text: 'Logbook entry', to: {name: ''}},
            ],
            requiresAuth: true
        }
    },
    {
        path: '*',
        component: NotFoundView,
        meta: {requiresAuth: true}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Store.state.isAuthenticated) {
            next();
            return;
        }
        next({name: 'login', query: {'next': to.path}});
    } else {
        next();
    }
});

export default router
