<template>
  <v-container fluid>
    <v-tabs>
      <v-tab>{{ $tc('Personal') }}</v-tab>
      <v-tab v-if="isAdmin">{{ $tc('Administration', 1) }}</v-tab>
      <v-tab-item>
        <user-preferences-component></user-preferences-component>
      </v-tab-item>
      <v-tab-item v-if="isAdmin">AdminComponent...</v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import BaseCardComponent from "@/components/templates/BaseCardComponent.vue";
import UserPreferencesComponent from "@/components/UserPreferencesComponent.vue";

export default {
  name: 'settings',
  components: {UserPreferencesComponent, BaseCardComponent},
  data() {
    return {
      isAdmin: false
    }
  },
  mounted () {
    document.title = 'Guardport Settings'
  },
}
</script>
