// Cookie Parser
export function parseCookie(data) {
    const parseCookie = str =>
        str
            .split(';')
            .map(v => v.split('='))
            .reduce((acc, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
                return acc;
            }, {});
    return (data) ? parseCookie(data) : [];
}
