<template>
  <base-card-component :title="$tc('Logbook entry', 1)" :show-title="true">
    <template v-slot:action>
<!--      <v-btn v-if="editable" icon small @click="editItem(logbook.id)"><v-icon>mdi-pencil</v-icon></v-btn>-->
      <v-btn v-if="deletable" icon small @click="deleteItem(logbook.id)"><v-icon>mdi-delete</v-icon></v-btn>
    </template>
    <template #default>
      <v-form ref="logbookentryform" v-model="valid">
        <v-row no-gutters>
          <v-col cols="12">
            {{ $tc('Category') }}:
            <v-chip label small v-if="logbook.category===0" class="primary mx-1">{{ $tc('Info') }}</v-chip>
            <v-chip label small v-if="logbook.category===1" class="warning mx-1">{{ $tc('Notice') }}/{{ $tc('Warning') }}</v-chip>
            <v-chip label small v-if="logbook.category===2" class="error mx-1">{{ $tc('Danger') }}/{{ $tc('Risk') }}</v-chip>
            <v-chip label small v-if="logbook.category===3" class="info mx-1">{{ $tc('Shift') }}</v-chip>

            {{ $tc('Entry Type') }}:
            <v-chip label small v-if="logbook.entry_type===0" class="primary mx-1">{{ $tc('Default') }}</v-chip>
            <v-chip label small v-if="logbook.entry_type===1" class="primary mx-1">{{ $tc('Sticky') }}</v-chip>
            <v-chip label small v-if="logbook.entry_type===2" class="primary mx-1">
              <v-icon small left color="white">mdi-phone-incoming</v-icon>
              {{ $tc('In') }}
            </v-chip>
            <v-chip label small v-if="logbook.entry_type===3" class="primary mx-1">
              <v-icon small left color="white">mdi-phone-outgoing</v-icon>
              {{ $tc('Out') }}
            </v-chip>

            <span v-if="logbook.alert_type">
              {{ $tc('Type') }}:
              <v-chip label small v-if="logbook.alert_type===0" class="mx-1">Internal</v-chip>
              <v-chip label small v-if="logbook.alert_type===1" class="mx-1">Verkada</v-chip>
              <v-chip label small v-if="logbook.alert_type===2" class="mx-1">Avigilon</v-chip>
            </span>
            <span v-if="logbook.client">
              {{  $tc('Client') }}:
              <v-chip label small v-if="logbook.client_shortname" class="mx-1">{{ logbook.client_shortname }}</v-chip>
            </span>
            <span v-if="logbook.project">
              {{  $tc('Project') }}:
              <v-chip label small v-if="logbook.project_shortname" class="mx-1">{{ logbook.project_shortname }}</v-chip>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea v-model="logbook.logtext" :label="$tc('Logtext')" :readonly="!editable" :rules="logtextRules" v-on:change="isDirty=true"></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="4">{{ $tc('Local Project time') }}</v-col>
          <v-col md="4">{{ $tc('UTC time') }}</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="4">
            <v-text-field :value="occurence" :label="$tc('Time of occurrence')" readonly></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field :value="occurence_utc" :label="$tc('Time of occurrence')" disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="4">
            <v-text-field :value="created_at" :label="$tc('Created at')" readonly></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field :value="created_at_utc" :label="$tc('Created at')" disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="4">
            <v-text-field :value="logbook.created_by_fullname" :label="$tc('Created by')" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div v-if="editable">
        <v-btn plain @click.native="$emit('input', false)">{{ $tc('Cancel') }}</v-btn>
        <v-btn :disabled="!valid || !isDirty" @click="submitLogbookEntryform">{{ $tc('Save')}}</v-btn>
      </div>
    </template>
  </base-card-component>
</template>

<script>
import {DateTime, Duration} from "luxon";
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import api from "@/ApiFactory";

export default {
  components: {BaseCardComponent},
  props: ['logbook'],
  data() {
    return {
      editable: false,
      deletable: false,
      editMode: false,
      created_at: null,
      created_at_utc: null,
      occurence: null,
      occurence_utc: null,
      duration: 'not set',
      core_settings: [],
      allow_minutes_manager: 0,
      allow_minutes_user: 0,

      isDirty: false,
      valid: false,
      logtextRules: [
          v => !!v || 'Logtext is required',
          v => (v && v.length >= 5) || 'Logtext must be at least 5 characters',
      ],

    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1)
    },
    deleteItem(id) {
      console.log("delete: ", id)
    },
    submitLogbookEntryform() {
        const validated = this.$refs.logbookentryform.validate()
        if (validated) {
          this.$store.commit('activateLoading')
          api.logbook.update(this.logbook.id,
              {
                // 'category': this.category,
                'logtext': this.logbook.logtext,
                // 'created_by': this.$store.state.currentUser.id,
                // 'time_of_occurrence': DateTime.now(),
                // 'duration': ,
                // 'project': this.project.id,
                // 'client': this.project.client
              }
          ).then(
              response => {
                // refresh latest logbook entries
                this.$store.dispatch('logbook/fetchLatest')
                // show toast
                this.$root.vtoast.show({message: 'Successfully updated.'})
              }
          ).catch(error => {
              this.errorMessage = error
              console.log("create error: ", error)
          })
          this.$store.commit('deactivateLoading')

          // close form and go back
          this.navigateBack()
        }
      }
  },
  mounted() {
    this.created_at = DateTime.fromISO(this.logbook.created_at, {zone: this.logbook.project_timezone}).toFormat('yyyy-MM-dd TTT')
    this.created_at_utc = DateTime.fromISO(this.logbook.created_at).toUTC().toFormat('yyyy-MM-dd TTT')
    this.occurence = DateTime.fromISO(this.logbook.time_of_occurence, {zone: this.logbook.project_timezone}).toFormat('yyyy-MM-dd TTT')
    this.occurence_utc = DateTime.fromISO(this.logbook.time_of_occurence).toUTC().toFormat('yyyy-MM-dd TTT')

    // check user permissions
    // TODO: add check on time in global settings object!!!
    api.settings.retrieve(1)
        .then(
            response => {
              this.core_settings = response.data
              // noinspection JSUnresolvedVariable
              this.allow_minutes_manager = this.core_settings.logbook_allow_edit_by_manager
              // noinspection JSUnresolvedVariable
              this.allow_minutes_user = this.core_settings.logbook_allow_edit_by_user

              if (this.$store.state.currentUser.groups.includes('logbook_user')) {
                // noinspection JSCheckFunctionSignatures
                const allow_minutes = Duration.fromObject({ minutes: this.allow_minutes_user })
                const checkTime = DateTime.now().toUTC().minus(allow_minutes).toISO()

                // check if timerange is okay and user wants to edit only his own entry
                if (this.logbook.created_at > checkTime && this.logbook.created_by === this.$store.state.currentUser.id) {
                  this.editable = true
                }

              } else if (this.$store.state.currentUser.groups.includes('logbook_manager')) {
                // noinspection JSCheckFunctionSignatures
                const allow_minutes = Duration.fromObject({ minutes: this.allow_minutes_manager })
                const checkTime = DateTime.now().toUTC().minus(allow_minutes).toISO()

                if (this.logbook.created_at > checkTime) {
                  this.editable = true
                }
              }

            }
        )
  }
}
</script>
