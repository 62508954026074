<template>
  <vacation-calendar-component class="mt-3"></vacation-calendar-component>
</template>

<script>
import VacationCalendarComponent from "@/components/VacationCalendarComponent.vue";

export default {
  name: 'VacationView',
  components: {
    VacationCalendarComponent
  }
}
</script>

