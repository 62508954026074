<template>
  <base-card-component :title="title" :show-title="showTitle">
    <template #default>
      <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          :search="search"
          @click:row="forwardToObject"
      >
        <template v-slot:top>
          <v-text-field v-if="showSearch"
              v-model="search"
              :label="searchLabel"
              class="mx-4"
              append-icon="mdi-magnify"
              single-line
          ></v-text-field>
        </template>
        <template v-slot:item.is_main="{item}">
          <v-icon v-if="item.is_main" color="success">mdi-check-circle</v-icon>
          <v-icon v-else>mdi-minus-circle</v-icon>
        </template>
        <template v-slot:item.is_manager="{item}">
          <v-icon v-if="item.is_manager" color="success">mdi-check-circle</v-icon>
          <v-icon v-else>mdi-minus-circle</v-icon>
        </template>
        <template v-slot:item.is_reader="{item}">
          <v-icon v-if="item.is_reader" color="success">mdi-check-circle</v-icon>
          <v-icon v-else>mdi-minus-circle</v-icon>
        </template>
        <template v-slot:item.is_active="{item}">
          <v-icon v-if="item.is_active" color="success">mdi-check-circle</v-icon>
          <v-icon v-else>mdi-minus-circle</v-icon>
        </template>
        <template v-slot:no-data>No data available</template>
        <template v-slot:no-results>No search result</template>
      </v-data-table>
    </template>
  </base-card-component>
</template>


<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent";

export default {
  components: {BaseCardComponent},
  props: {
    memberships: {
      type: Array
    },
    /*
    displayedIObjects is a mandatory property that defines what side of the Membership is displayed.
    Options are 'projects' or 'guards'
    * */
    displayedObjects: {
      type: String,
      required: true
    },
    showTitle: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      showSearch: false,
      search: '',
      searchLabel: this.$t('search'),
      title: (this.displayedObjects === 'guards') ? this.$tc('Guard', 2) : this.$tc('Project', 2)
    }
  },
  computed: {
    headers() {
      let headers = null
      if (this.displayedObjects === 'guards') {
        headers = [
          {text: this.$t('fullname'), value: 'guard_fullname'},
          {text: this.$t('membership.main-project'), value: 'is_main'},
          {text: this.$t('membership.project-leader'), value: 'is_manager'},
          {text: this.$t('membership.project-reader'), value: 'is_reader'},
        ]
      } else if (this.displayedObjects === 'projects') {
        headers = [
          {text: this.$t('Shortname'), value: 'shortname'},
          {text: this.$t('membership.main-project'), value: 'is_main'},
          {text: this.$t('membership.project-leader'), value: 'is_manager'},
          {text: this.$t('membership.project-reader'), value: 'is_reader'},
        ]
      }
      return headers
    },
    items() {
      if (this.displayedObjects === 'guards') {
        return this.memberships
      } else if (this.displayedObjects === 'projects') {
        return this.memberships.map(membership => {
          return {
            shortname: membership.project_shortname,
            is_main: membership.is_main,
            is_reader: membership.is_reader,
            is_manager: membership.is_manager,
            id: membership.project
          }
        })
      }
    }
  },
  methods: {
    forwardToObject(e) {
      if (this.displayedObjects === 'guards') {
        console.log(e)
        this.$router.push({name: 'guard-details', params: {id: e.guard.toString()}})
      } else if (this.displayedObjects === 'projects') {
        this.$router.push({name: 'project-details', params: {id: e.id.toString()}})
      }
    }
  }
}
</script>
