<template>
  <BaseDataTableComponent :headers="headers"
                          :refresh-list-func="refreshListFunc"
                          :object-forward-func="forwardToObject"
                          :trigger.sync="trigger"
                          :row-clickable="false"
  >

    <!-- Modification of some slots -->
    <template v-slot:item.is_main="{item}">
      <v-icon v-if="item.is_main" color="success">mdi-check-circle</v-icon>
      <v-icon v-else>mdi-minus-circle</v-icon>
    </template>

    <template v-slot:item.is_manager="{item}">
      <v-icon v-if="item.is_manager" color="success">mdi-check-circle</v-icon>
      <v-icon v-else>mdi-minus-circle</v-icon>
    </template>

    <template v-slot:item.is_reader="{item}">
      <v-icon v-if="item.is_reader" color="success">mdi-check-circle</v-icon>
      <v-icon v-else>mdi-minus-circle</v-icon>
    </template>

    <template v-slot:item.is_active="{item}">
      <v-icon v-if="item.is_active" color="success">mdi-check-circle</v-icon>
      <v-icon v-else>mdi-minus-circle</v-icon>
    </template>

    <template #item.actions="{item}">
      <v-dialog
          width="500"
      >
        <template #activator="{on, attr}">
          <v-icon v-on="on" v-bind="attr">mdi-delete</v-icon>
        </template>
        <v-card>
          <v-card-title> {{ $t("Remove Guard from Project") }}</v-card-title>
          <v-card-text> {{ $t("Do you really want to delete guard") }} {{ item.guard_fullname }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-button-component
                @click="deleteGuard(item.id)"
            >
              {{ $t("Confirm") }}
            </base-button-component>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:no-data>No data available</template>

    <template v-slot:no-results>No search result</template>

    <!-- Element to add new project members -->
    <template #footer v-if="$store.state.groups.includes('project_manager')">
      <v-row class="mt-2">
        <v-col cols="12" md="4" lg="4" xl="4">
          <FilterComponent :label="$t('Add-Guard')" :items="guardItems" v-model="guardValue"
                           :outlined="true" :multiple="true"
          >
            <template v-if="guardValue" #append>
              <!--              <v-icon @click="addGuard">mdi-account-plus</v-icon>-->
              <base-button-component @click="addGuard">{{ $t('add')}}</base-button-component>
            </template>
          </FilterComponent>
        </v-col>
        <!-- <v-col cols="2"> <v-checkbox v-model="isMain" :label="$t('membership.main-project')" ></v-checkbox></v-col> -->
        <v-col cols="6" md="2" lg="2" xl="2" class="mb-5" v-if="guardValue">
          <v-checkbox v-model="isManager" :label="$t('membership.project-leader')"></v-checkbox>
        </v-col>
        <v-col cols="6" md="2" lg="2" xl="2" v-if="guardValue">
          <v-checkbox v-model="isReader" :label="$t('membership.project-reader')"></v-checkbox>
        </v-col>
      </v-row>
    </template>

  </BaseDataTableComponent>
</template>


<script>
import BaseCardComponent from "@/components/templates/BaseCardComponent";
import BaseButtonComponent from "@/components/templates/BaseButtonComponent.vue";
import api from "@/ApiFactory";
import FilterComponent from "@/components/templates/FilterComponent.vue";
import BaseDataTableComponent from "@/components/templates/BaseDataTableComponent.vue";

export default {
  components: {BaseDataTableComponent, FilterComponent, BaseCardComponent, BaseButtonComponent},
  props: {
    project: {
      required: true
    },
    memberships: {
      type: Array,
      default: []
    },
    showTitle: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      showSearch: false,
      search: '',
      searchLabel: this.$t('search'),
      title: this.$tc('Guard', 2),
      guardValue: [],
      guardItems: [],
      isMain: false,
      isManager: false,
      isReader: true,
      trigger: false,
      itemList: null
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('fullname'), value: 'guard_fullname'},
        //{text: this.$t('membership.main-project'), value: 'is_main'},
        {text: this.$t('membership.project-leader'), value: 'is_manager'},
        {text: this.$t('membership.project-reader'), value: 'is_reader'},
        {text: '', value: 'actions'}
      ]
    },
    items() {
      return this.memberships
    }
  },
  methods: {
    async refreshListFunc(page) {
      this.$store.commit('activateLoading')
      let params = {}
      if (page) params.page = page
      // for (const [key, value] of Object.entries(this.filterObj)) {
      //   params[key] = value
      // }
      return await api.projects.members_by_project(this.project, params).then(
          response => {
            this.$store.commit('deactivateLoading')
            this.itemList = response.data.results
            return response.data
          }
      )
    },
    forwardToObject(e) {
      this.$router.push({name: 'guard-details', params: {id: e.guard.toString()}})
    },
    addGuard() {
      this.guardValue.map(guardId => {
        api.membership.create({
          project: this.project,
          guard: guardId,
          is_main: this.isMain,
          is_manager: this.isManager,
          is_reader: this.isReader
        }).then(r => {
          this.$root.vtoast.show({message: 'Guard successfully added'})
          this.trigger = true
          this.clearForm()
        }).catch(r => {
          this.$root.vtoast.show({message: 'Failed to add Guard', color: 'danger'})
          this.clearForm()
        })
      })
    },
    deleteGuard(id) {
      console.log(id)
      api.membership.delete(id).then(r => {
        this.$root.vtoast.show({message: 'Guard successfully deleted'})
        this.trigger = true
        this.retrieveGuardList()
      })
    },
    clearForm() {
      this.guardValue = null
      this.isManager = false
      this.isMain = false
      this.isReader = true
    },
    filterGuardList(items) {
      let newGuardItems = []
      for (const guard of this.guardItems) {
        if (!items.some(element => (element.guard_fullname === guard.text))) newGuardItems.push(guard)
      }
      this.guardItems = newGuardItems
    },
    retrieveGuardList() {
      api.guards.list().then(response => {
        this.guardItems = response.data.results.map(item => {
          return {text: item.first_name + ' ' + item.last_name, value: item.id}
        })
      })
    }
  },
  mounted() {
    this.retrieveGuardList()
  },
  watch: {
    itemList() {
      if (this.itemList) this.filterGuardList(this.itemList)
    }
  }
}
</script>
