import axios from "@/plugins/axios-config";
import api from "@/ApiFactory";

export default {
    // list Memberships by guard id
    async membershipsbyguard(id) {
        let memberships = await axios.get(`/projects/membershipbyguard/${id}/`)
        // add guard_display field to membership objects (this is needed since BE doesn't provide necessary data at that point)
        memberships.data.results = await Promise.all(memberships.data.results.map(async membership => {
            const guard_response = await api.guards.retrieve(membership.guard)
            membership.guard_display = guard_response.data
            const project_response = await api.projects.retrieve(membership.project)
            membership.project_display = project_response.data
            return membership
        }))
        return memberships
    },
    list(params) {
        return axios.get('/projects/membership/', {
            params: params
        })
    },
    create(data) {
        return axios.post('/projects/membership/', data)
    },
    retrieve(id, params) {
        return axios.get(`/projects/membership/${id}`, {
            params: params
        })
    },
    update(id, data) {
        return axios.patch(`/projects/membership/${id}`, data)
    },
    delete(id) {
        return axios.delete(`/projects/membership/${id}`)
    }
}
