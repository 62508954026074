<template>
  <v-app-bar app flat>
    <v-app-bar-nav-icon @click="$emit('toggleSidebar')"></v-app-bar-nav-icon>

      <!-- direct Logbook creation -->
      <!--suppress JSUnresolvedVariable -->
    <v-btn v-if="groups.includes('logbook_user') || groups.includes('logbook_manager')"
             icon :tooltip="true" @click="showLogbookEntryForm=true">
        <v-icon>mdi-book-plus</v-icon>
      </v-btn>
      <LogbookEntryCreateDialog
          v-if="showLogbookEntryForm"
          v-model="showLogbookEntryForm">
      </LogbookEntryCreateDialog>

      <!-- Loading bar -->
      <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="deep-purple accent-4"
      ></v-progress-linear>

      <v-spacer></v-spacer>

      <!-- Alerticon indicates if there are NEW alarms -->
      <v-btn icon v-if="$store.state.alerts.new_alerts_count"
             color="error"
             @click="navigateToAlerts"
      >
        <v-icon>mdi-bell</v-icon>
      </v-btn>

      <!-- Avatar with setting menu -->
      <v-avatar
          color="blue"
          @click="showSettingMenu">
        <span class="white--text">{{ $store.state.initials }}</span>
      </v-avatar>

      <!-- Settings Menue -->
      <v-menu v-model="settingsMenu"
              :position-x="menuPosX"
              :position-y="menuPosY"
              style="max-width: 600px">
        <v-list>
          <v-list-item link :to="{name: 'settings'}">
            <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
            <v-list-item-content>{{ $t('Settings') }}</v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{name: 'changelog'}">
            <v-list-item-icon><v-icon>mdi-new-box</v-icon></v-list-item-icon>
            <v-list-item-content>{{ $t('What\'s new') }}</v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content>{{ $t('Logout') }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
  </v-app-bar>
</template>

<script>
import LogbookEntryCreateDialog from "@/components/LogbookEntryCreateDialog";
import {mapState} from "vuex";
import {LOGOUT} from "../../store/action-types";

export default {
  name: "AppHeader",
  components: {LogbookEntryCreateDialog},
  props: {

  },
  data() {
    return {
      showLogbookEntryForm: false,
      settingsMenu: false,
      menuPosX: 0,
      menuPosY: 0,
      alertcount: 0,
      polling_alerts: null,
      alerts: []
    }
  },
  methods: {
    showSettingMenu(e) {
      this.menuPosX = e.clientX
      this.menuPosY = e.clientY
      this.settingsMenu = true
    },
    pollAlerts() {
      this.polling_alerts = setInterval( () => {
        if (this.groups.includes('alerts_user') || this.groups.includes('alerts_manager')) {
          this.$store.dispatch('alerts/FETCH_NEW_ALERTS_COUNT')
        }
        if (this.groups.includes('logbook_user') || this.groups.includes('logbook_manager')) {
          this.$store.dispatch('logbook/fetchLatest')
        }
      }, 30000)
    },
    navigateToAlerts () {
      this.$router.push({name: 'verkada-alerts'})
    },
    logout() {
      this.$store.dispatch(LOGOUT)
      // Redirect to login page
      this.$router.push({name: 'login'})
    },
    initUI() {
      this.$vuetify.theme.dark = this.preferences.ui_dark

      // we are duplicating the polling code here to achieve direct results after login or refresh
      if (this.groups.includes('logbook_user') || this.groups.includes('logbook_manager')) {
        this.$store.dispatch('logbook/fetchLatest')
      }
      if (this.groups.includes('alerts_user') || this.groups.includes('alerts_manager')) {
        this.$store.dispatch('alerts/FETCH_NEW_ALERTS_COUNT')
      }

    },
  },
  mounted: function() {
    this.initUI()
    this.pollAlerts()
  },
  watch: {
    groups() {
      this.initUI()
    }
  },
  beforeDestroy() {
      clearInterval(this.polling_alerts)
  },
  computed: {
    ...mapState(["loading", "groups", "preferences"])
  }
}
</script>
