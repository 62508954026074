import axios from "@/plugins/axios-config";

export default {
    // Retrieve Memberships associated with a specific guard
    memberships(id, params) {
        return axios.get(`/persons/guard/${id}/memberships_project/`, {params: params})
    },
    // Standard requests following
    list( params ) {
        return axios.get('/persons/guard/', {
            params: params
        })
    },
    create( data ) {
        return axios.post(`/persons/guard/`, data)
    },
    retrieve( id, params ) {
        return axios.get(`/persons/guard/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/persons/guard/${id}/`, data)
    }
}
