import axios from "@/plugins/axios-config";

export default {
    list_supported() {
      return axios.get('lookups/holidays/list_supported/')
    },
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/lookups/holidays/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/lookups/holidays/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/lookups/holidays/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/lookups/holidays/${id}/`, data)
    },
}
