<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <alert-verkada-component v-if="verkadaalert" :verkadaalert="verkadaalert"></alert-verkada-component>
      </v-col>
      <v-col>
        <logbook-entry-component v-if="verkadaalert" :verkadaalert="verkadaalert"></logbook-entry-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlertVerkadaComponent from "@/components/AlertVerkadaComponent";
import LogbookEntryComponent from "@/components/LogbookEntryComponent";
import api from "@/ApiFactory";

export default {
  components: {AlertVerkadaComponent, LogbookEntryComponent},
  props: ['id'],
  data() {
    return {
      verkadaalert: null,
    }
  },
  methods: {
    fetchVerkadaAlert() {
      this.$store.commit('activateLoading')
      api.alertsverkada.retrieve(this.id).then(
          response => {
            this.verkadaalert = response.data
          }
      )
      this.$store.commit('deactivateLoading')
    },
  },
  mounted() {
    this.fetchVerkadaAlert()
  }
}
</script>
