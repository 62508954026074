<template>
  <v-container fluid>
    <v-tabs>
      <v-tab v-if="logbook">{{ $tc('Info') }}</v-tab>
      <v-tab v-if="linked_alert">{{ $tc('Alert') }}</v-tab>
      <v-tab v-if="history">{{ $tc('History') }}</v-tab>

      <v-tab-item v-if="logbook">
        <logbook-details-component v-if="logbook" :logbook="logbook"></logbook-details-component>
      </v-tab-item>
      <!-- TODO: should also check if this is really type Verkada -->
      <v-tab-item v-if="linked_alert">
        <alert-verkada-component :verkadaalert="linked_alert"></alert-verkada-component>
      </v-tab-item>
      <v-tab-item v-if="history">
        <logbook-history-component v-if="history" :history="history" :logbook="logbook"></logbook-history-component>
      </v-tab-item>

    </v-tabs>
  </v-container>
</template>

<script>
import api from "@/ApiFactory";
import LogbookDetailsComponent from "@/components/LogbookDetailsComponent";
import AlertVerkadaComponent from "@/components/AlertVerkadaComponent";
import LogbookHistoryComponent from "@/components/LogbookHistoryComponent.vue";

export default {
  components: {LogbookHistoryComponent, AlertVerkadaComponent, LogbookDetailsComponent},
  props: ['id'],
  data() {
    return {
      logbook: null,
      linked_alert: null,
      history: null,
    }
  },
  methods: {
    fetchLogbook() {
      this.$store.commit('activateLoading')
      api.logbook.retrieve(this.id).then(
          logbook_response => {
            this.logbook = logbook_response.data
            if (this.logbook.alert !== null) {
              api.alertsverkada.retrieve(this.logbook.alert).then(
                  response => {
                    this.linked_alert = response.data
                  }
              )
          }
          api.logbook.history(this.logbook.id).then(
              history_response => {
                this.history = history_response.data
              }
          )
      })
      this.$store.commit('deactivateLoading')
    },
  },
  mounted() {
    this.fetchLogbook()
  }
}
</script>
