<template>
        <v-menu
        ref="menu"
        v-model="menu"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            :disabled="disabled"
            clearable
            v-bind="attrs"
            v-on="on"
            @input="$emit('input', $event)"
          ></v-text-field>
        </template>
        <v-date-picker
          :value="value"
          no-title
          @input="$emit('input', $event)"
        >
        </v-date-picker>
      </v-menu>
</template>

<script>

export default {
  /* Same behavior as v-autocomplete  */
  name: 'DateRangePickerComponent',
  props: {
    value: {
      type: null,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false
    }
  },
}
</script>