import axios from "@/plugins/axios-config";

export default {
    // special endpoints
    members_by_project(id) {
        return axios.get(`/projects/project/${id}/members/`)
    },
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/projects/project/', {
            params: params
        })
    },
    create( data ) {
        return axios.post(`/projects/project/`, data)
    },
    retrieve( id, params ) {
        return axios.get(`/projects/project/${id}`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/projects/project/${id}/`, data)
    }
}
