<template>
  <v-container fluid>
    <v-tabs>
      <v-tab v-if="guard">Info</v-tab>
      <v-tab v-if="memberships">
        {{ $tc('project', 4) }}
        <v-badge inline color="grey" :content="memberships.length" v-if="memberships.length"></v-badge>
      </v-tab>
      <v-tab v-if="vacationaccount">
        {{ $tc('vacation', 1) }}
        <v-badge inline color="grey"
                 :content="vacationaccount.remaining_days_total"
                 v-if="vacationaccount.remaining_days_total"
        >
        </v-badge>
      </v-tab>

      <v-tab-item v-if="guard">
        <guard-details-component :guard="guard" :is-manager="isManager"></guard-details-component>
      </v-tab-item>

      <v-tab-item v-if="memberships">
        <MembershipTableComponent :memberships="memberships"
                                  displayed-objects="projects"
                                  :show-title="false"
        >
        </MembershipTableComponent>
      </v-tab-item>

      <v-tab-item v-if="vacationaccount">
        <!-- vacation account expanson pane incl. edit options -->
        <vacation-account-details-component :vacationaccount="vacationaccount"
                                            class="mt-5"
        >
        </vacation-account-details-component>
        <!-- calendar view displaying vacation entries -->
        <vacation-calendar-component v-if="guard" :guard="guard" class="mt-3"></vacation-calendar-component>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import VacationAccountDetailsComponent from "@/components/VacationAccountDetailsComponent";
import GuardDetailsComponent from "@/components/GuardDetailsComponent";
import api from "@/ApiFactory"
import MembershipTableComponent from "@/components/MembershipTableComponent";
import CalendarComponent from "@/components/CalendarComponent";
import VacationCalendarComponent from "@/components/VacationCalendarComponent";

export default {
  components: {
    VacationCalendarComponent,
    CalendarComponent, MembershipTableComponent, GuardDetailsComponent, VacationAccountDetailsComponent},
  props: ['id'],
  data() {
    return {
      guard: null,
      vacationaccount: null,
      memberships: null,
      isManager: this.$store.state.currentUser.groups.includes('guard_manager')
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.$store.commit('activateLoading')
    api.guards.retrieve(this.id).then(guard => {
      this.guard = guard.data
      const params = {user_id: this.guard.user}
      api.vacationaccount.list(params).then(vacationaccount => {
        // Retrieve vacation account. Here it is assumed that each guard has always only one vacation account
        this.vacationaccount = vacationaccount.data.results[0]
      })
      api.guards.memberships(this.id).then(memberships => {
        this.memberships = memberships.data.results
      })
      this.$store.commit('deactivateLoading')
    })
  }
}
</script>
