import Auth from "@/api/auth";
import Alerts from "@/api/alerts";
import AlertsVerkada from "@/api/alerts-verkada";
import Clients from "@/api/clients";
import Guards from "@/api/guards"
import Logbook from "@/api/logbook";
import LookupCounty from "@/api/lookup_county";
import LookupCountry from "@/api/lookup_country";
import LookupHolidays from "@/api/lookup_holidays";
import Memberships from "@/api/memberships";
import Projects from "@/api/projects";
import ReportingUtils from "@/api/reporting_utils";
import Settings from "@/api/core/settings";
import User from "@/api/user";
import UserPreferences from "@/api/user-preferences";
import VacationAccount from "@/api/vacation_account";
import Vacations from "@/api/vacations";


// initialize the API factories
const factories = {
    auth: Auth,
    alerts: Alerts,
    alertsverkada: AlertsVerkada,
    guards: Guards,
    clients: Clients,
    logbook: Logbook,
    loookupcounty: LookupCounty,
    lookupcountry: LookupCountry,
    lookupholidays: LookupHolidays,
    membership: Memberships,
    projects: Projects,
    reportingutils: ReportingUtils,
    settings: Settings,
    user: User,
    userpreferences: UserPreferences,
    vacationaccount: VacationAccount,
    vacations: Vacations
}
export default factories

// export const ApiFactory = {
//     get: name => factories[name]
// }
