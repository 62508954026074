import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/lookups/country/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/lookups/country/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/lookups/country/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/lookups/country/${id}/`, data)
    },
}
