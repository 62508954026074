<template>
  <v-dialog v-model="show"
            max-width="350"
            @click:outside="$emit('closed')"
            @close="$emit('closed')"
  >

    <v-card v-if="showFormCard">
      <v-card-title>Edit Vacation</v-card-title>
      <v-card-text>
        <v-select
            v-model="type"
            :items="typeOptions"
            item-value="value"
            item-text="name"
            label="Type"
        ></v-select>
        <v-text-field
            label="Start"
            v-model="start"
            @click="showDatePickerStart=true"
        ></v-text-field>
        <v-text-field
            label="End"
            v-model="end"
            @click="showDatePickerEnd=true"
        ></v-text-field>
        <v-textarea
            label="Note"
            v-model="note"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="success" @click="submit">submit</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <!--date picker start -->
    <v-date-picker v-if="showDatePickerStart"
                   v-model="start"
                   :allowed-dates="disablePastDates"
                   @change="showDatePickerStart=false"
                   @update:active-picker="showFormCard=false"
    >
    </v-date-picker>

    <!--date picker start -->
    <v-date-picker v-if="showDatePickerEnd"
                   v-model="end"
                   :allowed-dates="disableDatesBeforeStartDate"
                   @change="showDatePickerEnd=false"
                   @update:active-picker="showFormCard=false"
    >
    </v-date-picker>
  </v-dialog>
</template>

<script>
import {Vacation} from "@/utils/vacation";

export default {
  name: 'EditVacationDialog',
  props: ['show', 'vacation'],
  data() {
    return {
      showDialog: false,
      typeOptions: [{name: "paid vacation", value: Vacation.TYPE_PAID},
        {name: "unpaid vacation", value: Vacation.TYPE_UNPAID}],
      start: this.vacation.start.toISOString().substring(0,10),
      end: this.vacation.end.toISOString().substring(0,10),
      type: this.vacation.type,
      note: this.vacation.note,
      showDatePickerStart: false,
      showDatePickerEnd: false,
      showFormCard: true
    }
  },
  methods: {
    submit() {
      let vacation = new Vacation()
      vacation.id = this.vacation.id
      vacation.start = new Date(this.start)
      vacation.end = new Date(this.end)
      vacation.type = this.type
      vacation.user = this.vacation.user
      vacation.note = this.note
      vacation.requested_at = this.vacation.requested_at
      vacation.reviewed_at = new Date()
      console.log(vacation)
      this.$emit('edit', vacation)
      this.$emit('closed')
    },
    disablePastDates(val) {
      return val >= new Date().toISOString()
    },
    disableDatesBeforeStartDate(val) {
      if (this.disablePastDates(val)) {
        return val >= this.start
      } else {
        return false
      }
    },
  },
  watch: {
    'vacation'() {
      this.start = this.vacation.start.toISOString().substring(0,10)
      this.end = this.vacation.end.toISOString().substring(0,10)
      this.type = this.vacation.type
      this.note = this.vacation.note
    },
    'show'() {
      this.showDatePickerEnd = false
      this.showDatePickerStart = false
    },
    showDatePickerStart(value) {
      if (value) {
        this.showDatePickerEnd = false
        this.showFormCard = false
      } else {
        this.showFormCard = true
      }
    },
    showDatePickerEnd(value) {
      if (value) {
        this.showDatePickerStart = false
        this.showFormCard = false
      } else {
        this.showFormCard = true
      }
    },
  },
}
</script>