<template>
  <v-container fluid>
    <v-card>
      <h3>Sorry. The requested address was not found.</h3>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "NotFound",
  }
</script>
