<template>
  <v-autocomplete
      :value="value"
      :items="items"
      :outlined="outlined"
      dense
      chips
      small-chips
      :disabled="disabled"
      deletable-chips
      :label="label"
      :multiple="multiple"
      class="mt-2"
      @input="$emit('input', $event)"
  >
    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"/>
    </template>
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"/>
    </template>

    <!-- Define default no data slot-->
    <template #no-data>
      <v-list>
        <v-list-item>
          {{ $t('No data available') }}
        </v-list-item>
      </v-list>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  /* Same behavior as v-autocomplete  */
  name: 'FilterComponent',
  props: {
    value: {
      type: null,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    }
  }
}
</script>