<template>
  <base-card-component :title="$tc('Alert', 1)" :show-title="true">
    <template #default>
      <v-row no-gutters>
        <v-col>
          <span v-if="verkadaalert.alert_type">
            {{ $tc('Type') }}:
            <v-chip label small v-if="verkadaalert.alert_type===0" color="warning">{{ "UNKNOWN" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_type===1" color="info">{{ "NOTIFICATION" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_type===2" color="warning">{{ "WARNING" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_type===3" color="error">{{ "ALARM" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_type===4" color="error">{{ "EMERGENCY" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_type===5" color="info">{{ "LPR" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_type===6" color="info">{{ "SENSOR" }}</v-chip>
          </span>
        </v-col>
        <v-col>
          <span v-if="verkadaalert.alert_status">
            Status:
            <v-chip label small v-if="verkadaalert.alert_status===0" color="warning">{{ "NEW" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_status===1" color="info">{{ "PROGRESS" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_status===2" color="warning">{{ "PENDING" }}</v-chip>
            <v-chip label small v-else-if="verkadaalert.alert_status===3" color="success">{{ "CLOSED" }}</v-chip>
          </span>
        </v-col>
        <v-col>
          <span v-if="verkadaalert.client">
            {{ $tc('Client') }}:
            <v-chip label small v-if="verkadaalert.client_shortname" class="mx-1">{{
                verkadaalert.client_shortname
              }}</v-chip>
          </span>
        </v-col>
        <v-col>
          <span v-if="verkadaalert.project">
            {{ $tc('Project') }}:
            <v-chip label small v-if="verkadaalert.project_shortname" class="mx-1">{{
                verkadaalert.project_shortname
              }}</v-chip>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <span v-if="verkadaalert.camera_id">
            {{ $tc('Camera') }}:
            <v-chip label small v-if="verkadaalert.camera_name" class="mx-1">{{ verkadaalert.camera_name }}</v-chip>
            <v-chip label small v-else-if="!verkadaalert.camera_name" class="mx-1">{{ verkadaalert.camera_id }}</v-chip>
          </span>
        </v-col>
        <v-col md="6">
          <span v-if="verkadaalert.image_url">
            <v-img :src="verkadaalert.image_url" max-height="300px" max-width="400px"></v-img>
          </span>
          <span v-else-if="verkadaalert.thumbnail_url">
            <v-img :src="verkadaalert.thumbnail_url" max-height="300px" max-width="400px"></v-img>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <span v-if="verkadaalert.notification_type">
            {{ $tc('Notification_type') }}:
            <v-chip label small v-if="verkadaalert.notification_type" class="mx-1">{{
                verkadaalert.notification_type
              }}</v-chip>
          </span>
          <span v-if="verkadaalert.licence_plate_number">
            {{ $tc('Licence_plate', 1) }}:
            <v-chip label small v-if="verkadaalert.licence_plate_number"
                    class="mx-1">{{ verkadaalert.licence_plate_number }}</v-chip>
            {{ parseFloat(verkadaalert.licence_plate_confidence).toFixed(2) }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <span v-if="verkadaalert.video_url">
            {{ $tc('Video_url') }}:
            <v-btn :href="verkadaalert.video_url" target="verkada" small class="ml-2">
              <v-icon left>mdi-video</v-icon>
              Open video
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-col md="6">{{ $tc('Local Project time') }}</v-col>
        <v-col md="6">{{ $tc('UTC time') }}</v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field :value="created_at" :label="$tc('Created at')" readonly></v-text-field>
        </v-col>
        <v-col md="6">
          <v-text-field :value="created_at_utc" :label="$tc('Created at')" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="6">
          <v-text-field :value="verkadaalert.created_by_fullname" :label="$tc('Created by')" readonly></v-text-field>
        </v-col>
      </v-row>
    </template>

    <template #footer>
      <small>ID: <a @click="copyToClipboard(verkadaalert.id)"> {{ verkadaalert.id }}</a></small>
    </template>
  </base-card-component>
</template>

<script>
import {VerkadaAlert} from "@/utils/verkada_alert";
import BaseCardComponent from "@/components/templates/BaseCardComponent";

const {DateTime} = require("luxon");

export default {
  components: {BaseCardComponent},
  props: {
    verkadaalert: {
      type: VerkadaAlert,
    }
  },
  data() {
    return {
      created_relative: null,
      created_at: null,
      created_at_utc: null,
    }
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$root.vtoast.show({message: 'ID copied to clipboard'})
      }, () => {
        this.$root.vtoast.show({message: 'ID failed to copy to clipboard', color: 'danger'})
      });
    }
  },
  mounted() {
    this.created_relative = DateTime.fromISO(this.verkadaalert.created_at).toRelative()
    this.created_at = DateTime.fromISO(this.verkadaalert.created_at, {zone: this.verkadaalert.project_timezone}).toFormat('yyyy-MM-dd TTT')
    this.created_at_utc = DateTime.fromISO(this.verkadaalert.created_at).toUTC().toFormat('yyyy-MM-dd TTT')
  },
}
</script>
