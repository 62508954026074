// NPM
import Vue from 'vue'
import vuetify from './plugins/vuetify'

// PROJECT
import App from './App.vue'
import router from './router'
import store from '../store'
import i18n from "./i18n";
import AxiosConfig from "@/plugins/axios-config";
import ApiFactory from "@/ApiFactory";

Vue.config.productionTip = false

// Define API View as global variable
// Vue.prototype.$api_url = (Vue.config.productionTip) ? 'https://api.guardport.io/api/' : 'https://dev.guardport.io/api/'
Vue.prototype.$api_url = (Vue.config.productionTip) ? process.env.VUE_APP_API_ROOT : process.env.VUE_APP_API_ROOT

const pjson = require('/package.json');
Vue.prototype.$appversion = pjson.version

// Make preconfigured Axios globally available
Vue.prototype.$axios = AxiosConfig

// Make API calls globally available
Vue.prototype.$api = ApiFactory

// Define filters
Vue.filter('formatDate', function (value) {
  if (!value) return ''
  const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}
  return value.toLocaleDateString('en', options)
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
