<template>
  <v-tooltip bottom v-if="tooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             v-on="on"
             class="elevation-0 ma-2"
             :disabled="disabled"
             @click="emitClickEvent"
      >
        <slot></slot>
      </v-btn>
    </template>
    <slot name="tooltip"></slot>
  </v-tooltip>
  <v-btn v-else
         :disabled="disabled"
         @click="emitClickEvent"
         class="elevation-0 ma-2">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClickEvent(e) {
      this.$emit('click',e)
    }
  }
}

</script>