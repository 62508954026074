<template>
  <v-card outlined>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-row justify="center">
        You can't save this entry,<br/>
        because the alert is already closed.<br/>
        <v-btn color="warning" @click="overlay = false">
          OK
        </v-btn>
      </v-row>
    </v-overlay>
    <v-card-title>New Logbook Entry</v-card-title>
    <v-card-text>
      <v-form ref="logform" v-model="valid" >
        <v-chip-group v-model="category" mandatory>
          <v-chip disabled active-class="error" :value="-1">Ignore</v-chip>
          <v-chip active-class="primary" :value="0">Info</v-chip>
          <v-chip active-class="warning" :value="1">Notice/Warning</v-chip>
          <v-chip active-class="error" :value="2">Danger/Risk</v-chip>
        </v-chip-group>
        <v-row>
          <v-col sm="8">
            <v-textarea v-model="logtext" label="Logtext" :rules="logtextRules"></v-textarea>
          </v-col>
          <v-col sm="4">
            <v-btn small class="ma-1" @click="quickBtn('Resident Activity.', 0)">resident</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('Resident Vehicles.', 0)">res. vehicles</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('Staff activity.', 0)">staff</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('Workers working on property.', 0)">workers</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('Pedestrians outside property.', 0)">pedestrians</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('Vehicles outside property.', 0)">outs. vehicles</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('Camera went offline.', 1)">cam offline</v-btn>
            <v-btn small class="ma-1" @click="quickBtn('No suspicious activity.', -1)">ignore</v-btn>
          </v-col>
        </v-row>
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="submitLogform">Finish</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {Logbook} from "@/utils/logbook";
import {VerkadaAlert} from "@/utils/verkada_alert";
import api from "@/ApiFactory";

export default {
  props: {
    verkadaalert: {
      type: VerkadaAlert,
    }
  },
  data() {
    return {
      valid: true,
      category: 0,
      logtext: '',
      logtextRules: [
          v => !!v || 'Logtext is required',
          v => (v && v.length >= 5) || 'Logtext must be at least 5 characters',
      ],
      project: null,
      alert: null,
      absolute: true,
      overlay: false,
    }
  },

  methods: {
    quickBtn(fillText = '', category) {
      this.logtext += fillText
      this.category = category
    },
    submitLogform () {
      const validated = this.$refs.logform.validate()

      if (validated) {
        // prepare logbook object and save to API
        let logbook_obj = new Logbook()
        logbook_obj.category = this.category
        logbook_obj.logtext = this.logtext
        logbook_obj.created_by = this.$store.state.currentUser
        logbook_obj.time_of_occurence = this.verkadaalert.created_at
        // logbook_obj.duration = this.duration
        logbook_obj.client = this.verkadaalert.client
        logbook_obj.project = this.verkadaalert.project
        logbook_obj.alert = this.verkadaalert.id
        logbook_obj.alert_type = 1    // Type Verkada hardcoded for now | TODO: update hard coding

        // if alert is (still) of status NEW else show error and do not create entry
        if (this.verkadaalert.alert_status === 0) {

          this.$store.commit('activateLoading')

          api.logbook.create(logbook_obj).then(
              response => {
                this.$root.vtoast.show({message: 'Entry successfully created.'})
              }
          ).catch(error => {
              this.errorMessage = error
              console.log("create error: ", error)
          })
          this.$store.commit('deactivateLoading')

          // update alert object and send to API
          let verkadaalert_obj = this.verkadaalert
          // TODO: update hard coding
          verkadaalert_obj.alert_status = 3 // hardcoded type Closed
          this.$store.commit('activateLoading')

          // TODO: is it really needed to put here? patch would be way less data...
          api.alertsverkada.update(verkadaalert_obj.id, verkadaalert_obj).then(
              response => {
                console.log("VerkadaAlert updated", response)
              }
          ).catch(error => {
            this.errorMessage = error
            console.log("Updating error: ", error)
          })

          // send the user back to Alarm overview
          this.$router.push({name: 'verkada-alerts'})

        } else {
          console.log('alert is not NEW, cancelling save')
          this.overlay = true
        }

      }

    },
  }
}
</script>
