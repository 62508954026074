import axios from "@/plugins/axios-config";

export default {
    // Review vacation endpoint
    review(id, data) {
        return axios.post(`/availability/vacation/${id}/review/`, data)
    },
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/availability/vacation/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/availability/vacation/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/availability/vacation/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/availability/vacation/${id}/`, data)
    }
}
