import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    breakpoint: {
        mobileBreakpoint: 'sm'  // equivalent to a value of 960
    },
    theme: {dark: false},
});
