<template>
  <v-footer app>
    <v-row>
      <v-col class="text-center">
        <small>
          &copy; 2022-{{ new Date().getFullYear() }} — All rights reserved by ISN International Security Network GmbH - v{{ $appversion }}
        </small>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter.vue"
}
</script>
