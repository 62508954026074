<template>
  <v-row justify="center">
    <v-dialog :value="value" @input="$emit('input', $event)" persistent max-width="600px">
      <v-card>
        <v-card-title>{{ $tc('New-logbookentry', 1) }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="logbookentryform" v-model="valid">
              <v-row no-gutters>
                <v-col class="d-flex align-center" :cols="2">{{ $tc('Category') }}</v-col>
                <v-col class="d-flex align-center">
                  <v-chip-group v-model="category" mandatory @change="setTemplateItems">
                    <v-chip active-class="primary" :value="0">{{ $tc('Info') }}</v-chip>
                    <v-chip active-class="warning" :value="1">{{ $tc('Notice') }}/{{ $tc('Warning') }}</v-chip>
                    <v-chip active-class="error" :value="2">{{ $tc('Danger') }}/{{ $tc('Risk') }}</v-chip>
                    <v-chip active-class="info" :value="3">{{ $tc('Shift') }}</v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="d-flex align-center" :cols="2">{{ $tc('Entry Type') }}</v-col>
                <v-col class="d-flex align-center">
                  <v-chip-group v-model="entry_type" mandatory>
                    <v-chip active-class="primary" :value="0">{{ $tc('Default') }}</v-chip>
                    <v-chip disabled active-class="primary" :value="1">{{ $tc('Sticky') }}</v-chip>
                    <v-chip active-class="primary" :value="2">
                      <v-icon left>mdi-phone-incoming</v-icon>
                      {{ $tc('In') }}
                    </v-chip>
                    <v-chip active-class="primary" :value="3">
                      <v-icon left>mdi-phone-outgoing</v-icon>
                      {{ $tc('Out') }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col sm="12">
                  <v-menu v-model="showTemplateMenu"
                          top
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-textarea v-model="logtext" label="Logtext" :rules="logtextRules"
                                  @click.right="openContext"
                                  @contextmenu.prevent
                      ></v-textarea>
                    </template>
                    <v-list>
                      <v-list-item
                          v-for="(item, index) in templateItems"
                          :key="index"
                      >
                        <v-list-item-title @click="fillWithText(item.title)">{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12">
                  <v-autocomplete
                      v-model="project"
                      :items="projectList"
                      item-text="shortname"
                      item-value="id"
                      :search-input.sync="search"
                      :label="$tc('Project')"
                      placeholder="Start typing to search"
                      :rules="[v => !!v || 'Item is required']"
                      return-object
                      clearable
                      auto-select-first
                      required
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click.native="$emit('input', false)">{{ $tc('Cancel') }}</v-btn>
          <v-btn :disabled="!valid" @click="submitLogbookEntryform">{{ $tc('Save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<script>
import {DateTime} from "luxon";
import api from "@/ApiFactory";

export default {
  components: {},

  props: {
    value: Boolean,
  },
  data() {
    return {
      valid: false,
      category: 0,
      entry_type: 0,
      logtext: '',
      logtextRules: [
        v => !!v || 'Logtext is required',
        v => (v && v.length >= 5) || 'Logtext must be at least 5 characters',
      ],
      // Autocomplete
      project: null,
      projectList: null,
      search: null,
      // Snackbar
      snackbar: false,
      text: 'Logbook entry created',
      timeout: 2000,
      showTemplateMenu: false,
      templateItems: null,
      templateMenuPosition: {x: 200, y: 200}
    }
  },
  methods: {
    preLoadDropdowns() {
      api.projects.list()
          .then(response => {
            this.projectList = response.data.results
          })
    },
    openContext(e) {
      console.log(e)
      this.templateMenuPosition.x = e.clientX
      this.templateMenuPosition.x = e.clientY
      if (this.templateItems)
        this.showTemplateMenu = true
    },
    fillWithText(text) {
      this.logtext = text
    },
    setTemplateItems() {
      if (this.category == 3) {
        this.templateItems = [
          {title: 'Shift started'},
          {title: 'Shift ended'}
        ]
      }
    },
    submitLogbookEntryform() {
      const validated = this.$refs.logbookentryform.validate()

      if (validated) {
        this.$store.commit('activateLoading')
        api.logbook.create(
            {
              'category': this.category,
              'entry_type': this.entry_type,
              'logtext': this.logtext,
              'created_by': this.$store.state.currentUser.id,
              'time_of_occurence': DateTime.now(),
              // 'duration': ,
              'project': this.project.id,
              'client': this.project.client
            }
        ).then(
            response => {
              this.$store.dispatch('logbook/fetchLatest')
              this.$root.vtoast.show({message: 'Successfully created.'})
            }
        ).catch(error => {
          this.errorMessage = error
          console.log("create error: ", error)
        })
        this.$store.commit('deactivateLoading')

        // close dialog
        this.$emit('input', false)
      }
    }
  },
  mounted() {
    this.preLoadDropdowns()
  },
  watch: {
    search(value) {
      api.projects.list()
          .then(response => {
            this.projectList = response.data.results
          })
    }
  }
}
</script>
