import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, create, retrieve, update, delete)
    list() {
        // this will always return only hte current users data
        return axios.get('/users/preferences/')
    },
    retrieve( user_id ) {
        return axios.get(`/users/preferences/${user_id}/`)
    },
    update( user_id, data ) {
        return axios.put(`/users/preferences/${user_id}/`, data)
    }
}
