import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/alerts/verkada-alert/', {
            params: params
        })
    },
    retrieve( id, params ) {
        return axios.get(`/alerts/verkada-alert/${id}`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/alerts/verkada-alert/${id}/`, data)
    },
}
