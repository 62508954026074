// Definition of model class for Verkada alerts

export class VerkadaAlert {
    constructor(id = null,
                client = 0,
                client_shortname = '',
                project = 0,
                project_shortname = '',
                project_timezone = '',
                alert_type = 0,
                alert_status = 0,
                created_by = null,
                created_by_fullname = '',
                created_at = '',
                camera_id = '',
                camera_name = '',
                notification_type = '',
                person_label = null,
                crowd_threshold = null,
                image_url = '',
                video_url = '',
                thumbnail_url = '',
                licence_plate_number = '',
                licence_plate_confidence = '',
    ) {
        this.id = id
        this.client = client
        this.client_shortname = client_shortname
        this.project = project
        this.project_shortname = project_shortname
        this.project_timezone = project_timezone
        this.alert_type = alert_type
        this.alert_status = alert_status
        this.created_by = created_by
        this.created_by_fullname = created_by_fullname
        this.created_at = created_at
        this.camera_id = camera_id
        this.camera_name = camera_name
        this.notification_type = notification_type
        this.person_label = person_label
        this.crowd_threshold = crowd_threshold
        this.image_url = image_url
        this.video_url = video_url
        this.thumbnail_url = thumbnail_url
        this.licence_plate_number = licence_plate_number
        this.licence_plate_confidence = licence_plate_confidence
    }
}
