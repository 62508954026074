import axios from "@/plugins/axios-config";

export default {
    login( credentials ) {
        return axios.post('/tokenauth/', credentials)
    },

    getCurrentUser() {
        return axios.get('/users/current_user/')
    },

}
