<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar class="v-app-bar" elevation="2">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ul v-if="errors" class="error-messages">
              <li v-for="(v, k) in errors" :key="k">{{ k }} {{ v | error }}</li>
            </ul>
            <v-form @submit.prevent="login(username, password)">
              <v-text-field
                  name="login"
                  label="Login"
                  type="text"
                  v-model="username"
                  @keyup.enter="login"
              ></v-text-field>
              <v-text-field
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  v-model="password"
                  @keyup.enter="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="login(username, password)">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {LOGIN} from "/store/action-types";

export default {
  name: 'Login',
  data() {
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    login(username, password) {
      this.$store.dispatch(LOGIN, {username, password})
    },
  },
  mounted() {
    document.title = 'Guardport Login'
  },
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  watch: {
    '$store.state.isAuthenticated'(state) {
      if (state) {
        if (this.$route.query.next) {
          this.$router.push(this.$route.query.next)
        } else {
          this.$router.push({name: 'home'})
        }
      }
    }
  }
}
</script>
