import axios from "@/plugins/axios-config";

export default {
    // default endpoints (list, create, retrieve, update, delete)
    list( params ) {
        return axios.get('/lookups/county/', {
            params: params
        })
    },
    create( data ) {
        return axios.post('/lookups/county/', data)
    },
    retrieve( id, params ) {
        return axios.get(`/lookups/county/${id}/`, {
            params: params
        })
    },
    update( id, data ) {
        return axios.put(`/lookups/county/${id}/`, data)
    },
}
